// @ts-check
/** @typedef {import("./models").GptAd} GptAd  */

import { log } from "./utils/log";

/**
 * Creates a slot for an ad element in GPT.
 * @param {GptAd} ad
 * @param {string} adunitpath
 * @returns {googletag.Slot}
 */
export function createSlot(ad, adunitpath) {
  // This is setup when the ad is instantiated.
  const data = ad.data;

  // This may be undefined
  let gptSlot = ad.gptSlot;

  // The second time function is called,
  // some ads may already have sizes.
  if (!gptSlot) {
    // Create the slot
    gptSlot = googletag
      .defineSlot(adunitpath, [0, 0], data.id)
      .addService(googletag.pubads());
    log("Created slot for ad: " + data.id);
  }

  // At the last possible moment before defining
  // the slot, set its slotname to targeting.
  ad.data.targeting.slotname = ad.getSlotName();

  // Set key/value targeting
  for (let key in data.targeting) {
    gptSlot.setTargeting(key, data.targeting[key]);
  }

  // Responsive sizemapping
  let sizeMapping = googletag.sizeMapping();
  data.breakpoints.forEach(function (bp) {
    sizeMapping.addSize(bp.viewport, bp.sizes);
  });

  gptSlot.defineSizeMapping(sizeMapping.build());
  window.onDvtagReady(function () {
    googletag.display(ad.id);
  });

  return gptSlot;
}
