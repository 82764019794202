// @ts-check
import { setGlobals } from "../options";

export default function sessionDepth() {
  if (sessionStorage.sessionDepth) {
    sessionStorage.sessionDepth = Number(sessionStorage.sessionDepth) + 1;
  } else {
    sessionStorage.sessionDepth = 1;
  }

  setGlobals({ session_depth: sessionStorage.sessionDepth });
}
