// @ts-check

/**
 * Wrap callbacks to cause their exceptions to
 * be console logged.
 *
 * Some vendor queues (GPT, Prebid) catch and supress
 * these, making it easy to create silent bugs.
 *
 * It does not support arguments at this time.
 *
 * @param      {Function}  func    The function to call
 */
export function logExceptions(func) {
  return function() {
    try {
      func();
    } catch (e) {
      console.error(e);
    }
  };
}
