// @ts-check
import { GptAd } from "../models";
import { log } from "./log";

/**
 * Look at all the format values `<gpt=ad format=""` on the page and count how many there are.
 * Setter function. Sets `globals` map in ../options.
 * For example, if there are 5 ads on the pages with an "injector" format:
 * globals['injector-count'] = [1,2,3,4,5]
 *
 * This must be run just before global targeting is set to ensure
 * it catches all the ads.
 *
 * @returns {Object} - key/value targeting
 */
export function getFormatCounts() {
  // Make this first in the GPT queue, after
  // the elements are regsitered/can be counted
  // but before startGpt is called and pulls globals
  // into the page level targeting.
  // Whitelist the formats we'll target to.
  const expectedFormats = {
    injector: 0,
    rrail: 0,
  };

  // Count how many ads use each of those slotNames
  let allFormats = GptAd.all()
    .filter((ad) => ad.getActiveSizes().length)
    .map((ad) => ad.data.format || "");

  allFormats.forEach((cls) => {
    if (expectedFormats[cls] !== undefined) {
      expectedFormats[cls]++;
    }
  });

  let newGlobalVals = {};

  // Setup global targeting for each whitelisted class
  Object.keys(expectedFormats).forEach((key) => {
    if (expectedFormats[key] > 0) {
      // DFP can't target to `key < 5`, but AdOps needs to target,
      // for example, any page with at least 5 injectors.
      // To make their lives easy, we'll pass an array of every
      // number up to an including the number of eligible ads
      // on the page. This way they can target it kvr-injector-count = 5, ad
      // it will match on pages with 5 injectors or 20 injectors, but not
      // 2 injectors.
      const num = expectedFormats[key];
      const range = [];
      for (var i = 1; i <= num; i++) {
        range.push(i);
      }

      newGlobalVals[`${key}-count`] = range;
    }
  });

  log("Counted all the ads by format.");
  return newGlobalVals;
}
