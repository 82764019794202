// @ts-check
/** @typedef {import("./utils/types").GptAdElement} GptAdElement  */
import { log } from "./utils/log";
import { arraysEqual } from "./utils/compare";
import { flattenArray } from "./utils/flatten-array";
import { GptAd } from "./models";

/**
 * Determine if an ad's currently rendered size
 * is permitted at the new breakpoint.
 *
 * @param      {GptAd}   ad - The ad element
 * @return     {boolean}  - Does its size need to change?
 */
function breakpointHasActiveSize(ad) {
  if (!ad.size) {
    return false;
  }
  /** @type number[] */
  const adSize = ad.size;
  const sizes = ad.getActiveSizes();
  return sizes.filter((size) => arraysEqual(adSize, size)).length > 0;
}

//
// Creates mediaMatches for all the breakpoints.
//
export function setupBreakpoints() {
  // Only supported on browsers that have matchmedia.
  if (!window.matchMedia || window.matchMedia("all").addListener === undefined) {
    return;
  }

  // Find all the breakpoint widths
  const breakpoints = (() => {
    let widths = [];
    let bps = GptAd.all().map((ad) => ad.data.breakpoints);
    // Currently grouped in arrays by unit
    // Flatten, get width, and add to array
    flattenArray(bps)
      .map((bp) => bp.viewport[0])
      .forEach((w) => {
        if (widths.indexOf(w) === -1) {
          widths.push(w);
        }
      });

    // Ensure these are in numerical order
    widths = widths.sort((a, b) => {
      return a - b;
    });

    return widths;
  })();

  /**
   * When entering a new media query, reload
   * any ads that have already been called.
   */
  let handleBreakpoint = function (mql) {
    // Only fire for the band we're entering.
    // Otherwise it will refresh multiple times.
    if (!mql.matches) {
      return;
    }

    // All ads that have already been loaded,
    // exist in the DOM, and have a different
    // size loaded than allowed by the new breakpoint.
    let adsToRefresh = GptAd.all().filter(function (ad) {
      return (
        ad.called && document.body.contains(ad.element) && !breakpointHasActiveSize(ad)
      );
    });

    if (!adsToRefresh.length) {
      return;
    }

    log(
      `Crosses breakpoint ${mql.media}, clearing ads: ${adsToRefresh
        .map((ad) => ad.id)
        .join(", ")}`
    );

    // Clear all the slots that we might refresh and mark them
    // as uncalled and unloaded.
    adsToRefresh.forEach((ad) => ad.reset());
  };

  let mediaqueries = [];
  let min, max, mql, rule;
  // For each breakpoint, write a banded rule
  // for listening on the media query.
  // Rules should never overlap.
  for (let i = 0; i < breakpoints.length; i++) {
    min = breakpoints[i];
    max = breakpoints[i + 1];
    rule = "(min-width: " + min + "px)";
    if (max) {
      rule += " and (max-width: " + (max - 1) + "px)";
    }
    mql = window.matchMedia(rule);
    mql.addListener(handleBreakpoint.bind(this));
    mediaqueries.push(mql);
  }
  log("Created Breakpoints for Ads:", mediaqueries);
}
