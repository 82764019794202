// @ts-check
/** @typedef {import("../utils/types").Atlantic} Atlantic  */
/** @typedef {import("../utils/types").GptAdElement} GptAdElement  */
import { performance } from "../utils/perf";
import { pageViewUuid } from "../constants";
import { getDeviceType } from "../utils/device";
import { loadPixel } from "../utils/load";
import { convertObjectToParams } from "../utils/params";
import { getOptions, getGlobals } from "../options";
import { isDoNotSell } from "../consent";
import BidStore from "../bidstore";
import { GptAd } from "../models";
import { getPageCount } from "../utils/page-count";
import { logError, logTable } from "../utils/log";
import { cookies } from "../utils/cookies";

// When the controller initiated
let controllerStartTime = null;

// Native and house ads don't need to wait on lazy bidding.
// Track these independently.
let isFirstLoad = true;
let isFirstLoadWithLockingTasks = true;

const device = getDeviceType();

/**
 * Determine the sizes that are eligible to serve in this ad
 * @param {Object} ad The ad object
 * @return {String} All eligible ad sizes
 */
function getEligibleSizes(ad) {
  const sizesArray = ad.getActiveSizes();
  return sizesArray
    .map((sizes) => {
      return sizes.join("x");
    })
    .join(",");
}

function logTimeToFirstAdStats() {
  // Detailed logging
  const ttfaFromController = performance.getEntriesByName("ads:controller_to_first_ad")[0]
    ?.duration;
  const ttfaWithTasksFromController = performance.getEntriesByName(
    "ads:controller_to_first_ad_with_tasks"
  )[0]?.duration;

  const domContentLoaded =
    performance.getEntriesByType("navigation")[0]?.domContentLoadedEventEnd;
  const ttfaFromDomContentLoaded =
    performance.getEntriesByName("ads:first_ad")[0]?.startTime - domContentLoaded;
  const ttfaWithTasksFromDomContentLoaded =
    performance.getEntriesByName("ads:first_ad_with_tasks")[0]?.startTime -
    domContentLoaded;

  logTable({
    TimeToFirstAd: {
      fromControllerReady: ttfaFromController,
      fromDomContentLoaded: ttfaFromDomContentLoaded,
    },
    TimeToFirstAdWithTasks: {
      fromControllerReady: ttfaWithTasksFromController,
      fromDomContentLoaded: ttfaWithTasksFromDomContentLoaded,
    },
  });
}

/**
 * Record's ads performance metrics
 * to a pixel on the CDN.
 * @param  {GptAd} ad    A `gpt` element
 */
function recordLoadTime(ad) {
  const { perfUrl, globals } = getOptions();

  // The ads that were lazy-loadable from the top of the page
  // should be timed from when the controller is ready. Later ads are
  // timed from when they enter the lazy-loader.
  let now = performance.now();
  ad.loadtime = now - (ad.startTime || 0);
  if (ad.inLazyRangeAtPageLoad) {
    ad.loadtime = now - controllerStartTime;
  }
  ad.loadtime = Math.round(ad.loadtime);

  // Perf markers
  if (isFirstLoad) {
    performance.mark("ads:first_ad");
    performance.measure("ads:dfp_roundtrip", "ads:first_call", "ads:first_ad");
    performance.measure(
      "ads:controller_to_first_ad",
      "ads:controller_loaded",
      "ads:first_ad"
    );
    logTimeToFirstAdStats();
  }
  if (isFirstLoadWithLockingTasks && ad.tasks.length) {
    performance.mark("ads:first_ad_with_tasks");
    performance.measure(
      "ads:controller_to_first_ad_with_tasks",
      "ads:controller_loaded",
      "ads:first_ad_with_tasks"
    );
    logTimeToFirstAdStats();
  }

  // Stop here if no performance
  // endpoint was configured.
  if (!perfUrl) {
    return;
  }

  const bids = ad.getBids();

  let data = {
    // Timings
    init: controllerStartTime,
    isfirst: isFirstLoad,
    isFirstWithTasks: isFirstLoadWithLockingTasks,
    time: ad.loadtime,
    stalls: ad.stalls,
    batch: ad.batch,

    // Device/user
    device: device,
    donotsell: isDoNotSell() ? 1 : 0,
    pageviewnum: getPageCount(),
    referrer: getGlobals().referrer || "none",
    ga_id: cookies.get("_ga"),
    cdnTestBucket: cookies.get("atltestbucketv1"),

    // Page
    url: location.href,
    pageviewid: pageViewUuid,
    abt: globals.abt,
    adsOnPage: GptAd.all().length,

    // Ad
    advId: ad.advertiserId,
    crId: ad.creativeId,
    cmpId: ad.campaignId,
    LnId: ad.lineItemId,
    zone: ad.getGptSlot().getAdUnitPath(),
    size: ad.size ? ad.size.join("x") : "",
    eligibleSizes: getEligibleSizes(ad),
    pos: (ad.data.targeting.pos || []).join(","),
    format: ad.data.format,
    bidcount: Object.keys(bids).length,
    maxBid: ad.getMaxBid(),
    isRefresh: ad.data.targeting.refresh || 0,
  };

  // Include bids
  for (let key in bids) {
    data[`bid:${key}`] = bids[key];
  }
  data.previousBids = BidStore.getBids();
  data.avgBid = BidStore.getAverageBid().toFixed(2);

  // Convert parameters into a url
  let params = convertObjectToParams(data);
  let url = `${perfUrl}?${params}`;
  loadPixel(url);

  // Not first anymore
  isFirstLoad = false;
  if (ad.tasks.length) {
    isFirstLoadWithLockingTasks = false;
  }
}

//
// Record how long it takes for every
// ad to load.
//
export default function timing() {
  controllerStartTime = Math.round(performance.now());

  if (!getOptions().perfUrl) {
    return;
  }

  // We patch performance with noops on old browsers. However,
  // it's completely useless to measure performance on browsers
  // that don't have the API to do it, and winds up recording
  // garbage because its doing math on undefined. Don't do it.
  if (performance.now() === undefined) {
    return;
  }

  window.addEventListener("ad-loaded", (e) => {
    if (!e.target) {
      logError("ad-loaded event fired without a target", e);
      return;
    }
    const ad = GptAd.getById(e.target.id);
    if (ad) {
      recordLoadTime(ad);
    }
  });
}
