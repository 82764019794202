// @ts-check
/** @typedef {import("./types").CustomEventName} CustomEventName */

/**
 * Dispatches a custom event on an element.
 *
 * @param      {CustomEventName}       name     Event's name
 * @param      {HTMLElement | Window}  [element=window]  Element to trigger on and bubble up from
 * @param      {EventInit}   [options]  Event options, default bubbles and cancelable
 */
export function dispatch(
  name,
  element = window,
  options = {
    bubbles: true,
    cancelable: true,
  }
) {
  const event = new Event(name, options);
  element.dispatchEvent(event);
}
