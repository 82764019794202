// @ts-check

const tabletBreakpoint = 1010;
const mobileBreakpoint = 767;

export const DevicesEnum = {
  mobile: "mobile",
  tablet: "tablet",
  desktop: "desktop",
};

/**
 * @return {String} "mobile", "desktop", or "tablet"
 */
export function getDeviceType() {
  let ua = window.navigator.userAgent || "";
  let w = window.screen.width || window.innerWidth;

  let name = DevicesEnum.desktop;

  // by device width
  if (w < mobileBreakpoint) {
    name = DevicesEnum.mobile;
  }

  // Override by UA
  if (
    ua.match(
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i
    )
  ) {
    name = DevicesEnum.tablet;
  } else if (ua.match(/Mobile|iP(hone|od)|BlackBerry|IEMobile/i)) {
    name = DevicesEnum.mobile;
  }
  return name;
}

export function getDeviceBreakpoint() {
  const w = window.innerWidth || window.screen.width;

  if (w < mobileBreakpoint) {
    return DevicesEnum.mobile;
  }
  if (w < tabletBreakpoint) {
    return DevicesEnum.tablet;
  }
  return DevicesEnum.desktop;
}
