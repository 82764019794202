// @ts-check
export const formats = {
  adhesion: "adhesion",
  cineflex: "cineflex",
  injector: "injector",
  logo: "logo",
  "native-video": "native-video",
  native: "native",
  promo: "promo",
  recirc: "recirc",
  rrail: "rrail",
  video: "video",
  leaderboard: "leaderboard",
};
