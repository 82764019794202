// @ts-check

import { debug } from "../constants";

const hasDebugFlag = Object.keys(debug).length;
// This binding will preserve the line number in the console
export let log = hasDebugFlag ? console.log.bind(window.console) : () => {};

export let logError = hasDebugFlag ? console.error.bind(window.console) : () => {};

export let logTable =
  hasDebugFlag && console.table ? console.table.bind(window.console) : () => {};
