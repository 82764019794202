// @ts-check
/** @typedef {import("../models").GptAd} GptAd  */

/**
 * @typedef {Object} Breakpoint
 * @property {string[]} viewport
 */

export function getActiveBreakpoint(ad) {
  return ad.data.breakpoints
    .map((item) => {
      return item.viewport[0];
    })
    .sort((a, b) => {
      return b - a;
    })
    .filter((width) => {
      return matchMedia(`(min-width: ${width}px)`).matches;
    })[0];
}

/**
 * Get list of sizes for the active
 * breakpoint on this ad.
 * @param  {GptAd} ad - A GptAd Instance.
 * @return {Array<Array<number>>} - List of sizes, e.g. [[300, 250], [728, 90]]
 */
export function getSizesAtCurrentBreakpoint(ad) {
  let breakpoints = ad.data.breakpoints
    .sort(function (item) {
      return item.viewport[0];
    })
    .filter(function (item) {
      return matchMedia("(min-width: " + item.viewport[0] + "px)").matches;
    });
  return breakpoints[breakpoints.length - 1].sizes;
}
