// @ts-check
import { loadScript } from "../utils/load";

const url = `${window.location.protocol}//${window.location.host}/static/advertising/bookmarklet.js`;

/**
 * Loads the bad ad bookmarklet if there's a #badad hash
 */
export default function loadBookmarklet() {
  const check = () => {
    if (window.location.hash === "#badad") {
      loadScript(url, "bookmarklet");
    }
  };
  check();
  window.onhashchange = check;
}
