// @ts-check
import { getOptions, setGlobals } from "../options";

/**
 * Determines if something about a page will likely
 * cause DoubleVerify to blacklist it for brand safety.
 *
 * The implementation will grow as we learn more about DV and
 * how to manage it.
 *
 * 1. DV takes 20-40 minutes to analyze pages, and in the meantime
 *    just assumes they're about all the bad things we cover site-wide.
 *    Therefore nothing id DV-ready for the first hour of publication.
 */
export default function doubleVerifyReady() {
  let isReady = false;

  const { datePublished } = getOptions();

  // Must be published for at least one hour
  if (datePublished) {
    // Using Unix time to avoid TZ hell
    const published = new Date(datePublished).getTime();
    const now = new Date().getTime();
    const diff = now - published;

    // Convert unix time (in milliseconds) to hours
    const hoursPublished = diff / 1000 / 60 / 60;
    isReady = hoursPublished > 1;
  }

  setGlobals({ dv_ready: isReady ? "yes" : "no" });
}
