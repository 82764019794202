// @ts-check

import { performance } from "../utils/perf";
import { log, logError } from "../utils/log";
import { loadAjax } from "../utils/load";
import { allowAllTracking } from "../consent";

/**
 * @typedef GeoData
 * @property {string} [country]
 * @property {string} [region]
 * @property {boolean} [is_gdpr]
 * @property [speed]
 */

/**
 * Page-level cache
 * @type {GeoData}
 */
let geodata = {
  country: undefined,
  region: undefined,
};

const STORAGE_NAME = "adsgeo2";

export function isDataStale(lastUpdated) {
  const diff = Date.now() - new Date(lastUpdated).valueOf();
  if (isNaN(diff)) {
    return true;
  }
  const days = diff / 1000 / 60 / 60 / 24;
  return days > 14;
}

export function syncGeoData(callback) {
  callback = callback || function () {};

  // Try to keep this in localstorage, save the API call
  let data = {};
  try {
    const str = localStorage.getItem(STORAGE_NAME);
    data = (typeof str === "string" && JSON.parse(str)) || {};
    geodata = data;
  } catch (e) {
    logError(`Ads: error parsing localstorage: ${STORAGE_NAME}`);
  }

  // If there's no reason to update the data, we're done
  if (isDataStale(data.lastUpdated) === false) {
    return callback();
  }

  // If there is stale data, resolve but keep working in
  // the background
  if (Object.keys(data).length > 0) {
    log("Ads: updating your geodata in the background");
    callback();
  }

  // Do the API call
  loadAjax("/api/user_country/")
    .then((data) => {
      data.lastUpdated = new Date();

      // I'm picking a whitelist of this data.
      // Note that anything you store in localstorage IS
      // readable by ad creatives.
      geodata = {
        country: data.country,
        region: data.region,
        is_gdpr: data.is_gdpr,
        speed: data.speed,
      };

      callback();

      // Remember this for a while
      localStorage.setItem(STORAGE_NAME, JSON.stringify(geodata));

      log("Updated geodata");
      performance.mark("ads:synced_new_geodata");
    })
    .catch(callback); // Don't break ads over this
}

/**
 * Load Geodata before doing something
 *
 * We're going to add a _tiny_ delay so that the fastest browsers
 * will have a chance to download the data before we call all the headerbidders.
 *
 * @param      {number}  [timeout=50]  Time allowed before proceeding anyway
 * @return     {Promise<any|void>}  - Resolved when we have data or timed out
 */
export function requireGeoData(timeout = 50) {
  // extra safeguard to never get geo data when non-personalized in the app
  if (!allowAllTracking()) {
    return /** @type {Promise<void>} */ (new Promise((resolve) => resolve()));
  }
  performance.mark("ads:geodata_start");
  let didGetGeoData = false;

  return /** @type {Promise<void>} */ (
    new Promise(function (resolve, reject) {
      syncGeoData(() => {
        didGetGeoData = true;
        log("Ads: Geodata is ready 🌎");
        performance.mark("ads:geodata_ready");
        resolve();
      });

      setTimeout(function () {
        if (!didGetGeoData) {
          log("Ads: Geodata lookup timed out 🌎");
          resolve();
        }
      }, timeout);
    })
  ).then(() => {
    performance.mark("ads:geodata_done");
    performance.measure("ads:geodata_delay", "ads:geodata_start", "ads:geodata_done");
  });
}

export function getGeoDataSync() {
  return geodata;
}
