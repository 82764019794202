// @ts-check
/** @typedef {import("../models").GptAd} GptAd  */
/**
 * Keep track of which ads are in the viewport
 */
export const inViewObserver = window.IntersectionObserver
  ? new window.IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const ad = window.__ads.get(entry.target.id);
        if (ad) {
          ad.inView = entry.isIntersecting;
        }
      });
    })
  : {
      observe: () => {},
      unobserve: () => {},
    };
