// @ts-check

/**
 * Given a nested array, return a flat array.
 * Only does one level deep.
 *
 * @See: MDN on Array.flat Alternatives https://mzl.la/2lPIArm
 *
 * @param      {Array}  arr     The arr
 */
export function flattenArray(arr) {
  return arr.reduce((acc, val) => acc.concat(val), []);
}
