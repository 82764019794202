// @ts-check
/**
 * @returns {String|void}
 */
export function uuid() {
  let cryptoRNG = window.crypto || window.msCrypto,
    bstring = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx",
    reg = new RegExp(/[xy]/g);
  if (
    typeof cryptoRNG !== "undefined" &&
    typeof cryptoRNG.getRandomValues !== "undefined"
  ) {
    return bstring.replace(reg, function (c) {
      let r = cryptoRNG.getRandomValues(new Uint8Array(1))[0] % 16 | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
