// @ts-check
import { log } from "./utils/log";
import { getOptions } from "./options";

// The unit
let outOfPageUnit = null;
let isCalled = false;

export function getOutOfPageUnit() {
  if (outOfPageUnit && !isCalled) {
    isCalled = true;
    log("Included outofpage ad in gpt call");
    return outOfPageUnit;
  }
  return null;
}

/**
 * Creates an out of page unit.
 */
export function createOutOfPageUnit() {
  const id = "outofpage";

  // Don't let this run twice
  if (outOfPageUnit) {
    return;
  }

  // Create the element if it doesn't exist already
  if (document.getElementById(id) === null) {
    const element = document.createElement("div");
    element.id = id;
    document.body.appendChild(element);
  }

  outOfPageUnit = googletag
    .defineOutOfPageSlot(getOptions().zone + "/outofpage", id)
    .addService(googletag.pubads())
    .setTargeting("pos", id);
  log("Created outofpage ad");
}
