// @ts-check
/**
 * Marker for a task that needs to be completed
 * before an ad is allowed to load.
 *
 * @class      LazyTask
 */
export class Task {
  /**
   * @param      {String}    name  Name of the task
   */
  constructor(name) {
    this.name = name;
    this.isComplete = false;
  }

  done() {
    this.isComplete = true;
  }
}
