// @ts-check
/**
 * Sets up the tfcapi queue mechanism, which prevents
 * race conditions against the CMP loading
 */
function makeStub() {
  var TCF_LOCATOR_NAME = "__tcfapiLocator";
  // @ts-ignore
  var queue = [];
  var win = window;
  var cmpFrame;

  function addFrame() {
    var doc = win.document;
    // @ts-ignore
    var otherCMP = !!win.frames[TCF_LOCATOR_NAME];

    if (!otherCMP) {
      if (doc.body) {
        var iframe = doc.createElement("iframe");

        iframe.style.cssText = "display:none";
        iframe.name = TCF_LOCATOR_NAME;
        doc.body.appendChild(iframe);
      } else {
        setTimeout(addFrame, 5);
      }
    }
    return !otherCMP;
  }

  function tcfAPIHandler() {
    var gdprApplies;
    var args = arguments;

    if (!args.length) {
      // @ts-ignore
      return queue;
    } else if (args[0] === "setGdprApplies") {
      if (args.length > 3 && args[2] === 2 && typeof args[3] === "boolean") {
        gdprApplies = args[3];
        if (typeof args[2] === "function") {
          args[2]("set", true);
        }
      }
    } else if (args[0] === "ping") {
      var retr = {
        gdprApplies: gdprApplies,
        cmpLoaded: false,
        cmpStatus: "stub",
      };

      if (typeof args[2] === "function") {
        args[2](retr);
      }
    } else {
      queue.push(args);
    }
  }

  // @ts-ignore
  function postMessageEventHandler(event) {
    var msgIsString = typeof event.data === "string";
    var json = {};

    try {
      if (msgIsString) {
        json = JSON.parse(event.data);
      } else {
        json = event.data;
      }
    } catch (ignore) {}

    // @ts-ignore
    var payload = json.__tcfapiCall;

    if (payload) {
      window.__tcfapi(
        payload.command,
        payload.version,
        function (retValue, success) {
          var returnMsg = {
            __tcfapiReturn: {
              returnValue: retValue,
              success: success,
              callId: payload.callId,
            },
          };
          if (msgIsString) {
            // @ts-ignore
            returnMsg = JSON.stringify(returnMsg);
          }
          event.source.postMessage(returnMsg, "*");
        },
        payload.parameter
      );
    }
  }

  while (win) {
    try {
      // @ts-ignore
      if (win.frames[TCF_LOCATOR_NAME]) {
        cmpFrame = win;
        break;
      }
    } catch (ignore) {}

    if (win === window.top) {
      break;
    }
    // @ts-ignore
    win = win.parent;
  }
  if (!cmpFrame) {
    addFrame();
    win.__tcfapi = tcfAPIHandler;
    win.addEventListener("message", postMessageEventHandler, false);
  }
}

export function ensureCMPQueueExists() {
  if (window.__tcfapi !== undefined) {
    return;
  }

  makeStub();
}
