// @ts-check
/** @typedef {import("../../utils/types").PrebidUnit} PrebidUnit  */
/** @typedef {import("../../models").GptAd} GptAd  */

import { allowedSizesByPriority } from "./settings";
import { formats } from "../../formats";

/**
 * Gets the best size that's available to bid on.
 *
 * @param      {Array} sizes - Should be strings ["300x250"]
 * @param      {Boolean} toArray - Do we need this back in [300,250] form?
 *                                 Assume we're working with keys otherwise.
 * @return     {String|number[]|undefined}  -  The size to bid on, if there's a match.
 */
export function getPreferredSize(sizes, toArray) {
  let result;
  for (let i = 0; i < allowedSizesByPriority.length; i++) {
    if (sizes.indexOf(allowedSizesByPriority[i]) !== -1) {
      result = allowedSizesByPriority[i];
      break;
    }
  }

  // Most of the time we're using strings as keys to look up
  // the placement ID's. If we need a real array, we can convert it.
  if (result && toArray) {
    result = result.split("x").map((i) => parseInt(i, 10));
  }

  return result;
}

/**
 * Internal map of prebid unit codes to ad ID's.
 * @type Object<string, string>
 */
const unitCodes = {};

/**
 * Gets the Prebid code for a unit.
 *
 * @param      {GptAd}  ad  An ad
 * @return     {String}  The unit code for Prebid tracking.
 */
export function getUnitCode(ad) {
  if (unitCodes[ad.id]) {
    return unitCodes[ad.id];
  }

  const value = `${ad.getSlotName()}/${ad.id}`;
  unitCodes[ad.id] = value;
  return value;
}

/**
 * @param {string} code
 * @returns {GptAd | undefined}
 */
export function getAdForUnitCode(code) {
  let ad;
  for (let key in unitCodes) {
    if (unitCodes[key] === code) {
      ad = window.__ads.get(key);
      break;
    }
  }
  if (ad === undefined) {
    console.error(`🚨 No ad matching Prebid code "${code}".
      Something is very wrong. 🚨`);
  }

  return ad;
}

/**
 * Convert the GPT Ad element into a Prebid object.
 * The result should be a PrebidUnit (defined in the comment above)
 *
 * Bidders will be added in the next step.
 *
 * @param      {GptAd}  ad - An ad element
 * @return     {PrebidUnit | void}  - Prebid's description of the ad unit
 */
export function describeUnit(ad) {
  // Programmatically determine the sizes
  const sizes = ad.getActiveSizes().filter((size) => {
    return allowedSizesByPriority.indexOf(size.join("x")) !== -1;
  });

  // Never include leaderboards
  if (ad.data.format === formats.leaderboard) {
    return;
  }

  // If there are no active sizes we obviously can't bid on it
  if (sizes.length === 0) {
    return;
  }

  /** @type PrebidUnit */
  const unit = {
    code: getUnitCode(ad),
    mediaTypes: {
      banner: {
        // @ts-ignore Prebid has their types set up wrong saying that this should be empty
        sizes: sizes,
      },
    },

    // We'll populate this next using functions from ./bidders.js
    bids: [],

    // Private helpers. These are not part of the Prebid API,
    // they're just to ensure all the functions can see what's going on easily.
    _slotName: ad.getSlotName(),
    _elementId: ad.id,

    // Global Placement ID (GPID)
    ortb2Imp: {
      ext: {
        gpid: `${ad.getAdUnitPath()}#${ad.id}`,
        data: {
          pbadslot: `${ad.getAdUnitPath()}#${ad.id}`,
        },
      },
    },
  };

  // Add outstream request
  if (getHasSize(unit, "1x3")) {
    unit.mediaTypes.video = {
      context: "outstream",
      playerSize: [[640, 480]],
      mimes: ["video/mp4"],
      protocols: [1, 2, 3, 4, 5, 6, 7, 8],
      playbackmethod: [2],
      skip: 1,
      playback_method: ["auto_play_sound_off"],

      // Required for Criteo to not implode
      maxduration: 15,
      api: [],
      placement: 3, // In article placement, per https://www.iab.com/wp-content/uploads/2016/03/OpenRTB-API-Specification-Version-2-5-FINAL.pdf
      // No Content/Standalone
      // https://github.com/InteractiveAdvertisingBureau/AdCOM/blob/develop/AdCOM%20v1.0%20FINAL.md#list_placementsubtypesvideo
      plcmt: 4,
    };
  }

  return unit;
}

/**
 *
 * @param {PrebidUnit} unit - Prebid unit structure
 * @param {String} requestedSize - e.g., "1x3"
 * @returns {Boolean}
 */
export function getHasSize(unit, requestedSize) {
  return !!unit.mediaTypes.banner.sizes.filter((size) => {
    // @ts-ignore Prebid has their types set up wrong saying that this should be empty
    return size.join("x") === requestedSize;
  }).length;
}
