// @ts-check
import { log } from "../utils/log";
import { setGlobals } from "../options";

/**
 * Chrome is trying to deprecate third-party cookies and has given us a
 * temporary API to see if the user is in a test group for deprecation.
 */
export default function chromeExperiments() {
  // Feature detect temporary API first
  if ('cookieDeprecationLabel' in navigator) {
    // Request value and resolve promise
    // @ts-ignore
    navigator.cookieDeprecationLabel.getValue().then((label) => {
      setGlobals({
        "chrome_cookies": label
      })
    });
  } else {
    log("Chrome Experiments: cookieDeprecationLabel not found in navigator");
  }
};
