// @ts-check

/**
 * Convert an object into a GET parameter string.
 * Replace this stuff with the URL object when we can
 * drop IE11.
 *
 * @param  {Object} data Key/values to use
 * @return {String}      Same data, ready to use as GET params
 */
export function convertObjectToParams(data) {
  let params = [];
  for (let key in data) {
    if (data[key] !== undefined) {
      // Omit empty params
      params.push(`${key}=${encodeURIComponent(data[key])}`);
    }
  }
  return params.join("&");
}
