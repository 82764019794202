// @ts-check
//     ___       _                     _    _       _
//    / _ \     | |                   | |  (_)     (_)
//   / /_\ \  __| |__   __  ___  _ __ | |_  _  ___  _  _ __    __ _
//   |  _  | / _` |\ \ / / / _ \| '__|| __|| |/ __|| || '_ \  / _` |
//   | | | || (_| | \ V / |  __/| |   | |_ | |\__ \| || | | || (_| |
//   \_| |_/ \__,_|  \_/   \___||_|    \__||_||___/|_||_| |_| \__, |
//                                                             __/ |
//    For The Atlantic                                        |___/                                                                    |___/
//
/** @typedef {import("./lib/utils/types").Options} Options */
import "./lib/gpt-events";
import "promise-polyfill/src/polyfill";

// You can export the controller for AMD
import Hummingbird from "./lib/controller";
import { initAPI } from "./lib/api";

// We'll name this back to normal when JSPM usage is deprecated
export const Controller = Hummingbird;

initAPI();
