// @ts-check
import { sizeDefinitions } from "./sizes";
import { stripIndent } from "./utils/string";
import { convertToArray } from "./utils/to-array";
import { getJwtData } from "./utils/jwt";

const { ad_free } = getJwtData();

/**
 * Default ID for ad elements
 * @type {String}
 */
const DefaultId = "gpt-unit-";

/**
 * Index counter for ad units
 * @type {number}
 */
let DefaultIdIndex = 0;

export function resetIdCount() {
  DefaultIdIndex = 0;
}

/**
 * Log error if an id is reused. This
 * would call all ads to fail.
 * @param  {string} id - gpt ad id
 */
function checkUnique(id) {
  // Raise errors if the ID is not unique.
  // This throws obscure errors that are
  // frustrating to debug.
  if (document.querySelectorAll(`[id="${id}"]`).length > 1) {
    console.error(
      stripIndent(`There's more than one element
              with the id of '${id}'.
              Unless the ids are unique no ads will load.`)
    );
  }
}

/**
 * Convert the <gpt-ad> size's string into a list
 * of sizes.
 *
 * The string may be a JSON list of numerical sizes
 * or a comma separated list of named size groups.
 *
 * e.g.,
 *
 * "[[300,250][1,3]]"
 *
 * or
 *
 * "full-width-mobile,native"
 *
 * @param      {string}  sizesString  gpt-sizeset's sizes's value
 * @return     {number[]}   List of dimensions for GPT
 */
function parseSizesList(sizesString) {
  // If it is a JSON list of numerical sizes
  if (sizesString.indexOf("[[") === 0 || sizesString === "[]") {
    // return list of ad sizes
    return filterforAdFree(JSON.parse(sizesString));
  }

  // If it is a comma-separated list of named size groups,
  // split on commas, and omit empty strings
  // take string, split into array and filter out any blanks
  const groupList = sizesString.split(",").filter((item) => item !== "");
  const result = [];

  // for each item in array, make sure it's a valid size
  groupList.forEach((item) => {
    item = item.trim();
    if (sizeDefinitions[item]) {
      sizeDefinitions[item].forEach((size) => result.push(size));
      return;
    }
    console.error(`Couldn't find ad sizes matching '${item}'`);
  });
  // return list of ad sizes
  return filterforAdFree(result);
}

/**
 * If the user should not see any ads except house ads
 * (eg is a premium subscriber),
 * filter out all ads except house ads.
 * If not, show them all ad sizes.
 *
 * @param {Array} sizesArray List of ad sizes
 * @returns {Array} Filtered or unfiltered list of ad sizes
 */
function filterforAdFree(sizesArray) {
  if (ad_free) {
    return sizesArray.filter((size) => size[0] === 3 && size[1] === 3);
  }
  return sizesArray;
}

/**
 * Convert old sizeset format to modern format so we only
 * need to support one.
 * @param    {HTMLElement} el `gpt` element
 */
function convertLegacySizesets(el) {
  let sizeSetElements = el.getElementsByTagName("gpt-sizeset");
  Array.prototype.map.call(sizeSetElements, function (ss) {
    const width = JSON.parse(ss.getAttribute("viewport-size"))[0];
    el.setAttribute(`sizes-at-${width}`, ss.getAttribute("sizes"));
  });
}

/**
 * Given a GPT-ad element, return everything
 * encoded in the DOM that describes the
 * ad call.
 *
 * @param    {HTMLElement} el `gpt` element
 * @return   {{ breakpoints: Object[], id: string | null, targeting: Object, format: String }} Data
 *              describing the element's instructions for ad calls
 *
 * @example
 *  // Given
 *  <gpt-ad id="foobar" targeting-pos="box" targeting-coffee="yes"
 *    sizes-at-0=""[[300, 250]]"
 *    sizes-at-640="[[1,3], [728,90], [728,350]]">
 *  </gpt-ad>
 *
 *  // Returns
 *  {
 *    id: "foobar",  // Element id
 *    fixedSizes: [],  // No non-responsive sizes
 *    breakpoints: [  // List of breakpoints and associated sizes
 *      {
 *        viewport: 0,
 *        sizes: [[300, 255]]
 *      },
 *      {
 *        viewport: 640,
 *        sizes: [[1,3], [728, 90], [728, 350]]
 *      }
 *    ],
 *    targeting: {  // Key values to target on the slot
 *      pos: "box",
 *      coffee: "yes"
 *    },
 *    format : 'rrail'
 *  }
 *
 **/
export function parseGptElement(el) {
  let data = {
    /** @type null | string */
    id: null,
    targeting: {},
    /** @type {{ viewport: number[], sizes: number[] }[]} */
    breakpoints: [],
    /** @type string */
    format: "",
  };

  // If there's no ID set, create one
  if (!el.id) {
    DefaultIdIndex++;
    el.id = `${DefaultId}${DefaultIdIndex}`;
  }

  checkUnique(el.id);

  data.id = el.id;
  data.targeting = {};

  convertLegacySizesets(el);

  const attributes = convertToArray(el.attributes);

  // Apply sizeset attributes
  attributes.forEach((attr) => {
    const prefix = "sizes-at-";
    if (attr.name.indexOf(prefix) === 0) {
      let minWidth = parseInt(attr.name.slice(prefix.length), 10);

      if (isNaN(minWidth)) {
        console.warn(`An ad has a breakpoint of '${minWidth}', which makes no sense 🚨`);
      }

      let sizes = parseSizesList(attr.value);

      data.breakpoints.push({
        viewport: [minWidth, 0],
        sizes: sizes,
      });
    }
  });

  // Apply key value targeting attributes
  attributes.forEach((attr) => {
    const prefix = "targeting-";
    if (attr.name.indexOf(prefix) === 0) {
      let key = attr.name.slice(prefix.length);
      let value = attr.value.split(",");
      data.targeting[key] = value;
    }
  });

  // There must be _some_ breakpoints
  if (data.breakpoints.length === 0) {
    console.error("🚒 No ad sizes were specified for", el);
  }

  data.format = getFormat(el);
  // TODO: After revops performs a kvp audit, we may be able to retire targeting.class
  data.targeting.class = [data.format];

  return data;
}

/**
 * @param {HTMLElement} el
 * @returns {string}
 */
function getFormat(el) {
  /** @type string | undefined | null */
  let format = el.getAttribute("format");

  // Fallback to kvr- class for now
  // TODO: Once Ad unit path refactor has rolled out, we can remove this
  if (!format) {
    format = createKvrClass(el);
  }

  return format || "other";
}

// @DEPRECATED, retire when there are no more KVR's
// On nearly all our ads, we have a class that starts with 'kvr-'
// (meaning 'key-value reporting') which Adops needs to determine
// what type of ad it is. This passes that ad type data over to DFP.
function createKvrClass(el) {
  // Note that items should be in order of priority. Things like
  // native that are useless for analysis should go last.
  const classWhiteList = [
    "injector",
    "rrail",
    "adhesion",
    "native",
    "logo",
    "recirc",
    "cineflex",
  ];

  const gptAdClasses = el.className.split(" ");
  let kvrClass = [];

  // If there is no class with that begins with 'kvr-',
  // set the data.targeting.class value to 'other'.
  // Otherwise, pull the kvr- class name (removing the kvr- prefix),
  // and set that as the value (e.g., 'injector').
  if (!gptAdClasses.filter((word) => word.indexOf("kvr-") !== -1).length) {
    kvrClass.push("other");
  } else {
    kvrClass = gptAdClasses
      .filter((gptAdClass) => gptAdClass.indexOf("kvr-") === 0)
      .map((gptAdClass) => gptAdClass.slice(4));
  }

  // Get the first class in the whitelist
  let primaryKvrClass;
  for (let i = 0; i < classWhiteList.length; i++) {
    if (kvrClass.indexOf(classWhiteList[i]) !== -1) {
      primaryKvrClass = classWhiteList[i];
      break;
    }
  }

  return primaryKvrClass;
}
