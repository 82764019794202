// @ts-check
import { loadScript } from "../utils/load";

/**
 * Brand safety. Sets page-level targeting so must complete before googletag.enableServices()
 * Also sets slot-level targeting.
 *
 * @docs: https://support.doubleverify.com/support/solutions/articles/22000284278-unity-tag-integration
 */
export default function doubleVerify(resolve) {
  loadScript(
    "https://pub.doubleverify.com/dvtag/22493059/DV1364739/pub.js",
    "doubleverify",
    resolve
  );

  // Calls to onDvtagReady should only happen after slots have been defined,
  // since it sets slot-level targeting.
  window.onDvtagReady = function (callback, timeout = 750) {
    window.dvtag = window.dvtag || {};
    dvtag.cmd = dvtag.cmd || [];
    const opt = { callback, timeout, timestamp: new Date().getTime() };
    dvtag.cmd.push(function () {
      dvtag.queueAdRequest(opt);
    });
    setTimeout(function () {
      const cb = opt.callback;
      opt.callback = null;
      if (cb) cb();
    }, timeout);
  };

  // We will call onDvtagReady later, so resolve immediately.
  resolve();
}
