// @ts-check
/** @typedef {import("./models").GptAd} GptAd  */

import { gptQueue } from "./queues";
import { inViewObserver } from "./utils/observe-inview";
import { log } from "./utils/log";

/**
 * Return ad to its initial state so it can
 * be called again.
 *
 * @param      {GptAd}  ad   The GptAd instance
 */
export function resetGptAd(ad) {
  log("Resetting ad", ad);
  ad.element.classList.remove("ad-called");
  // Strip out old loaded classes
  ad.element.className = ad.element.className
    .split(" ")
    .filter((cls) => cls.indexOf("ad-loaded") !== 0)
    .join(" ");

  // If this stuck around it would collide on the next pageview
  ad.element.removeAttribute("id");
  ad.element.removeAttribute("data-google-query-id");

  ad.called = false;
  ad.isResponsive = null;
  ad.size = null;
  ad.hadViewableImpression = null;
  ad.loaded = false;
  ad.tasks = [];
  inViewObserver.unobserve(ad.element);

  gptQueue.push(function () {
    googletag.pubads().clear([ad.getGptSlot()]);
  });
}
