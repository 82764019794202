// @ts-check
import { allowAllTracking } from "../consent";
import { loadScript } from "../utils/load";
import { removeURLParameter } from "../utils/url";

/**
 * For LiveIntent, the newsletter ads service.
 * It uses the ?lctg= query param to determine which newsletter the user came from
 */
export default function liveintent(resolve) {
  if (!allowAllTracking()) {
    removeURLParameter("lctg");
    return resolve();
  }

  const script = loadScript("//b-code.liadm.com/a-096g.min.js", "permutive", resolve);
  if (script) {
    // after a 300 ms timeout, remove the lctg param from the url
    script.addEventListener("load", () => {
      setTimeout(() => removeURLParameter("lctg"), 300);
    });
  }

  // LiveIntent is for ads in newsletters, not ads on site,
  // so we don't need to wait for it to load ads
  resolve();
}
