// @ts-check

/**
 * Simple array comparing, because in js
 * [1] !== [1]
 * Shallow arrays only.
 * @param {Array} arr1
 * @param {Array} arr2
 * @returns {boolean}
 */
export function arraysEqual(arr1, arr2) {
  // Identical arrays must be the same
  // length.
  if (arr1.length !== arr2.length) {
    return false;
  }

  // And each of their positions must be equal
  for (let i = 0; i < arr1.length; ++i) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}
