// @ts-check
import { jwtDecode } from "jwt-decode";

import { cookies } from "../utils/cookies";

const jwtCookie = "atljwt";

/**
 * @returns {object}
 */
export function getJwtData() {
  const token = cookies.get(jwtCookie);
  if (!token) {
    return {};
  }

  try {
    return jwtDecode(token);
  } catch (e) {
    console.warn("Issue decoding JWT token", e);
    return {};
  }
}

/**
 * Looks at JWT token to see if the user has paymeter access
 * * * @returns {Boolean}
 */
export function isSubscriber() {
  return getJwtData().paymeter_access;
}

/**
 * The user has logged in if we can find the JWT cookie
 * * @returns Boolean
 */
export function isLoggedIn() {
  return !!cookies.get(jwtCookie);
}
