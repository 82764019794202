// @ts-check
/**
 * A9 passes bidder's as keys.
 * Translate these to something that
 * makes sense. Will match the Line Item
 * generator.
 */
export const amazonBidderCodes = {
  yj9on4: "tam_index",
  szhszk: "tam_magnite",
  k3usqo: "tam_pulsepoint",
  izwf7k: "tam_dmx",
  b87shs: "tam_openx",
  b5pw5c: "tam_amazon",
  "2cks8w": "tam_districtm",
  "1si1tz4": "tam_emx_digital",
  "1lubksg": "tam_pubmatic",
  "1iigg74": "tam_yieldmo",
  "1e2my2o": "tam_gumgum",
  "1aqrthc": "tam_xandr",
  "18meps": "tam_oath",
  "16aybcw": "tam_triplelift",
  "156zxts": "tam_kargo",
};

/**
 * A9 passes obfuscated price codes.
 * Translate these to something that
 * makes sense.
 *
 * @type {Object.<string, string>}
 */
export const amazonPricesCodes = {
  r42jnk: "0.02",
  h5iww: "0.04",
  i8fjeo: "0.06",
  dsm1a8: "0.08",
  vjw1s0: "0.10",
  "4wz11c": "0.12",
  mo91j4: "0.14",
  bkpa80: "0.16",
  tbzaps: "0.18",
  "2p29z4": "0.20",
  kgcagw: "0.22",
  g0iscg: "0.24",
  xrssu8: "0.26",
  "74vs3k": "0.28",
  ow5slc: "0.30",
  agqwow: "0.32",
  s80x6o: "0.34",
  "1l3wg0": "0.36",
  jcdwxs: "0.38",
  ewketc: "0.40",
  wnufb4: "0.42",
  "60xekg": "0.44",
  ns7f28: "0.46",
  connr4: "0.48",
  ufxo8w: "0.50",
  "3t0ni8": "0.52",
  lkao00: "0.54",
  h4h5vk: "0.56",
  yvr6dc: "0.58",
  "88u5mo": "0.60",
  q0464g: "0.62",
  "9wrpxc": "0.64",
  ro1qf4: "0.66",
  "114pog": "0.68",
  iseq68: "0.70",
  ecl81s: "0.72",
  w3v8jk: "0.74",
  "5gy7sw": "0.76",
  n888ao: "0.78",
  c4ogzk: "0.80",
  tvyhhc: "0.82",
  "391gqo": "0.84",
  l0bh8g: "0.86",
  gkhz40: "0.88",
  ybrzls: "0.90",
  "7ouyv4": "0.92",
  pg4zcw: "0.94",
  b0q3gg: "0.96",
  ss03y8: "0.98",
  "25337k": "1.00",
  jwd3pc: "1.02",
  fgjlkw: "1.04",
  x7tm2o: "1.06",
  "6kwlc0": "1.08",
  oc6lts: "1.10",
  d8muio: "1.12",
  uzwv0g: "1.14",
  "4czu9s": "1.16",
  m49urk: "1.18",
  hogcn4: "1.20",
  zfqd4w: "1.22",
  "8stce8": "1.24",
  qk3cw0: "1.26",
  "92sxs0": "1.28",
  qu2y9s: "1.30",
  "75xj4": "1.32",
  hyfy0w: "1.34",
  dimfwg: "1.36",
  v9wge8: "1.38",
  "4mzfnk": "1.40",
  me9g5c: "1.42",
  bapou8: "1.44",
  t1zpc0: "1.46",
  "2f2olc": "1.48",
  k6cp34: "1.50",
  fqj6yo: "1.52",
  xht7gg: "1.54",
  "6uw6ps": "1.56",
  om677k: "1.58",
  a6rbb4: "1.60",
  ry1bsw: "1.62",
  "1b4b28": "1.64",
  j2ebk0: "1.66",
  emktfk: "1.68",
  wdutxc: "1.70",
  "5qxt6o": "1.72",
  ni7tog: "1.74",
  ceo2dc: "1.76",
  u5y2v4: "1.78",
  "3j124g": "1.80",
  lab2m8: "1.82",
  guhkhs: "1.84",
  ylrkzk: "1.86",
  "7yuk8w": "1.88",
  pq4kqo: "1.90",
  "9ms4jk": "1.92",
  re251c: "1.94",
  r54ao: "1.96",
  iif4sg: "1.98",
  e2lmo0: "2.00",
  "14pineo": "2.05",
  tlyw3k: "2.10",
  "1k8vwu8": "2.15",
  "7evdhc": "2.20",
  "1a9aj28": "2.25",
  jmdibk: "2.30",
  "1wge1og": "2.35",
  cyn94w: "2.40",
  "13lk9vk": "2.45",
  z5qrr4: "2.50",
  "1psnshs": "2.55",
  c5q80: "2.60",
  "1d669kw": "2.65",
  mj98u8: "2.70",
  "1spjj0g": "2.75",
  fviznk: "2.80",
  "16ig0e8": "2.85",
  s314hs: "2.90",
  "1ipy58g": "2.95",
  "5vxlvk": "3.00",
  "1c27w1s": "3.05",
  lfavb4: "3.10",
  "1y9beo0": "3.15",
  "9rrx8g": "3.20",
  "10eoxz4": "3.25",
  vyvfuo: "3.30",
  "1mlsglc": "3.35",
  "341o1s": "3.40",
  "1fy27eo": "3.45",
  pb56o0: "3.50",
  "1s5kc8w": "3.55",
  fbjsw0: "3.60",
  "15ygtmo": "3.65",
  uux2bk: "3.70",
  "1lhu328": "3.75",
  "8ntjpc": "3.80",
  "18gd62o": "3.85",
  htg5c0: "3.90",
  "1ungoow": "3.95",
  b5pw5c: "4.00",
  "11smww0": "4.05",
  xcterk: "4.10",
  "1nzqfi8": "4.15",
  "164idc": "4.20",
  "1e051q8": "4.25",
  nd80zk: "4.30",
  "1tjib5s": "4.35",
  gphrsw: "4.40",
  "17cesjk": "4.45",
  r92ccg: "4.50",
  "1hvzd34": "4.55",
  "51ytq8": "4.60",
  "1b893wg": "4.65",
  klc35s: "4.70",
  "1xfcmio": "4.75",
  alqpds: "4.80",
  "118nq4g": "4.85",
  wsu800: "4.90",
  "1nfr8qo": "4.95",
  "3y0g74": "5.00",
  "1gs0zk0": "5.05",
  q53ytc: "5.10",
  "1qp4gzk": "5.15",
  dv3xmo: "5.20",
  "14i0ydc": "5.25",
  teh728: "5.30",
  "1k1e7sw": "5.35",
  "77dog0": "5.40",
  "1a1su0w": "5.45",
  jevta8: "5.50",
  "1w8wcn4": "5.55",
  cr5k3k: "5.60",
  "13e2ku8": "5.65",
  yy92ps: "5.70",
  "1pl63gg": "5.75",
  "13mm0w": "5.80",
  "1dxn5ds": "5.85",
  naq4n4: "5.90",
  "1th0etc": "5.95",
  gmzvgg: "6.00",
  "179ww74": "6.05",
  sui0ao: "6.10",
  "1jhf11c": "6.15",
  "6nehog": "6.20",
  "1ctoruo": "6.25",
  m6rr40: "6.30",
  "1z0sagw": "6.35",
  "95au4g": "6.40",
  zs7uv4: "6.45",
  vcecqo: "6.50",
  "1lzbdhc": "6.55",
  "2hkkxs": "6.60",
  "1fbl4ao": "6.65",
  ooo3k0: "6.70",
  "1rj394w": "6.75",
  ep2ps0: "6.80",
  "15bzqio": "6.85",
  u8fz7k: "6.90",
  "1kvczy8": "6.95",
  "81cglc": "7.00",
  "197u1vk": "7.05",
  ikx14w: "7.10",
  "1vexkhs": "7.15",
  bx6ry8: "7.20",
  "12k3sow": "7.25",
  y4aakg: "7.30",
  "1or7bb4": "7.35",
  "1xle68": "7.40",
  "1erlxj4": "7.45",
  o4owsg: "7.50",
  "1uaz6yo": "7.55",
  hgynls: "7.60",
  "183vocg": "7.65",
  r1knb4: "7.70",
  "1hoho1s": "7.75",
  "4uh4ow": "7.80",
  "1b0rev4": "7.85",
  kdue4g: "7.90",
  "1x7uxhc": "7.95",
  ae90cg: "8.00",
  1116134: "8.05",
  wlciyo: "8.10",
  "1n89jpc": "8.15",
  "3qir5s": "8.20",
  "1gkjaio": "8.25",
  pxm9s0: "8.30",
  "1r43v28": "8.35",
  ea3bpc: "8.40",
  "14x0cg0": "8.45",
  ttgl4w: "8.50",
  "1kgdlvk": "8.55",
  "7md2io": "8.60",
  "1ags83k": "8.65",
  jtv7cw: "8.70",
  "1wnvqps": "8.75",
  d64y68: "8.80",
  "13t1yww": "8.85",
  zd8gsg: "8.90",
  "1q05hj4": "8.95",
  "4o16o": "9.00",
  "1cyokjk": "9.05",
  mbrjsw: "9.10",
  "1si1tz4": "9.15",
  fo1am8: "9.20",
  "16aybcw": "9.25",
  rvjfgg: "9.30",
  "1iigg74": "9.35",
  "5ofwu8": "9.40",
  "1buq70g": "9.45",
  l7t69s: "9.50",
  "1y1tpmo": "9.55",
  "9ka874": "9.60",
  "10778xs": "9.65",
  vrdqtc: "9.70",
  "1meark0": "9.75",
  "2wjz0g": "9.80",
  "1fqkidc": "9.85",
  p3nhmo: "9.90",
  "1ry2n7k": "9.95",
  f423uo: "10.00",
  "18u3lz4": "10.25",
  xqjuo0: "10.50",
  "1tx8r28": "10.75",
  "5fp9mo": "11.00",
  "11me60w": "11.25",
  qiueps: "11.50",
  "1jnnrwg": "11.75",
  cdf474: "12.00",
  "1djwphc": "12.25",
  sgrke8: "12.50",
  "1yn1ukg": "12.75",
  "2itj40": "13.00",
  "15d8oow": "13.25",
  im5zb4: "13.50",
  "1osg9hc": "13.75",
  hi7ls0: "14.00",
  "1an0yyo": "14.25",
  w7m328: "14.50",
  "1qqdf5s": "14.75",
  "78mmm8": "15.00",
  "13fbj0g": "15.25",
  mphzpc: "15.50",
  "1iw6w3k": "15.75",
  "9y0o3k": "16.00",
  "1g4ay9s": "16.25",
  v15t6o: "16.50",
  "1utpfk0": "16.75",
  "1cd98g": "17.00",
  "17injeo": "17.25",
  krku0w: "17.50",
  "1nlzzls": "17.75",
  dov6rk: "18.00",
  "19vk35s": "18.25",
  ys0buo: "18.50",
  "1tarny8": "18.75",
  "6h5qtc": "19.00",
  zlz400: "19.25",
  oifcow: "19.50",
  "1kp4934": "19.75",
  bqy134: "20.00",
  "1eld6o0": "20.25",
  r3g2kg: "20.50",
  "1x9qcqo": "20.75",
  "3se6f4": "21.00",
  "14yvrpc": "21.25",
  jvqmm8: "21.50",
  "1q20wsg": "21.75",
  fpwpvk: "22.00",
  "1bwlm9s": "22.25",
  vt962o: "22.50",
  "1rzy2gw": "22.75",
  "8i79xc": "23.00",
  "122016o": "23.25",
  nml5a8: "23.50",
  "1i5chds": "23.75",
  av3tog: "24.00",
  "1h1e3uo": "24.25",
  swdfk0: "24.50",
  "1vqsl4w": "24.75",
  liuio: "25.00",
  lonzls: "25.50",
  eyfu2o: "26.00",
  ydnev4: "26.50",
  "4ouuww": "27.00",
  ps0000: "27.50",
  d0ioe8: "28.00",
  s4wjr4: "28.50",
  "35x3b4": "29.00",
  hvbklc: "29.50",
  grd728: "30.00",
  wupn9c: "30.50",
  "730cu8": "31.00",
  n6ct1c: "31.50",
  "90xiio": "32.00",
  u42nls: "32.50",
  "1t82kg": "33.00",
  k9h2io: "33.50",
  e5q03k: "34.00",
  z8v56o: "34.50",
  "5k2l8g": "35.00",
  oza60w: "35.50",
  blbrb4: "36.00",
  roo7i8: "36.50",
  "4dmbcw": "37.00",
  j30sn4: "37.50",
  gb4utc: "38.00",
  vfiq68: "38.50",
  "84gu0w": "39.00",
  o7ta80: "39.50",
  a2dzpc: "40.00",
  thlkhs: "40.50",
  hgrgg: "41.00",
  lklwjk: "41.50",
  fguu4g: "42.00",
  xi4g00: "42.50",
  "579uyo": "43.00",
  qaf01s: "43.50",
  cjz3ls: "44.00",
  snbjsw: "44.50",
  "2ae4g0": "45.00",
  idqkn4: "45.50",
  h9s740: "46.00",
  wqnk74: "46.50",
  "7ro3r4": "47.00",
  mh2l1c: "47.50",
  "9pl9fk": "48.00",
  usqeio: "48.50",
  "1ix8n4": "49.00",
  ky4tfk: "49.50",
  dgfs3k: "50.00",
  "68qc5c": "51.00",
  c3qrcw: "52.00",
  "3i3chs": "53.00",
  fula0w: "54.00",
  "8mvu2o": "55.00",
  akszr4: "56.00",
  "12owe8": "57.00",
  eo505c: "58.00",
  "4tjf28": "59.00",
  d578jk: "60.00",
  "2vm9ds": "61.00",
  h45xc0: "62.00",
  "6uky68": "63.00",
  "97hhxc": "64.00",
  "1zs1z4": "65.00",
  dxalfk: "66.00",
  "6342dc": "67.00",
  bcwcn4: "68.00",
  "456wow": "69.00",
  ghou80: "70.00",
  "7w1fcw": "71.00",
  af6pz4: "72.00",
  "5lqtc": "73.00",
  f4zthc: "74.00",
  "5ae8e8": "75.00",
  c842yo: "76.00",
  "2pzzls": "77.00",
  hpe29s: "78.00",
  "7ft340": "79.00",
  "9spmv4": "80.00",
  172800: "81.00",
  dsgf0g: "82.00",
  "6kqz28": "83.00",
  buj9c0: "84.00",
  "3nv8jk": "85.00",
  flds00: "86.00",
  "8doc1s": "87.00",
  b32dj4: "88.00",
  thedc: "89.00",
  etww74: "90.00",
  "4kbx1c": "91.00",
  cvzqio: "92.00",
  "37mwao": "93.00",
  gt301s: "94.00",
  "6yheyo": "95.00",
  "8wekn4": "96.00",
  "1op4ow": "97.00",
  edojy8: "98.00",
  "5s1534": "99.00",
  bgstfk: "100.00",
};
