// @ts-check

/**
 * Remove a url parameter from the current url
 * @param {string} parameterName the ?name= of the parameter
 */
export function removeURLParameter(parameterName) {
  let url = new URL(window.location.href);
  let searchParams = new URLSearchParams(url.search);

  // Remove the specified parameter
  searchParams.delete(parameterName);
  url.search = searchParams.toString();

  // Update the browser's address bar without reloading the page
  window.history.replaceState({}, "", url.toString());
}
