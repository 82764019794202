// @ts-check
/** @typedef {import("../utils/types").Globals} Globals  */
import { setGlobals } from "../options";

/**
 * The data team may provide ad targeting
 * on the global page meta object. If its there,
 * pull it in.
 *
 * @param {Globals} globals
 */
export default function pageMeta(globals) {
  // grab it from the global window if it hasn't been supplied in the parameter
  if (!globals) {
    globals = ((window.Atlantic || {}).page_meta || {}).adtargeting;
  }
  if (globals) {
    setGlobals(globals);
  }
}
