// @ts-check

import { GptAd } from "../models";
import { log } from "../utils/log";
import { getOptions } from "../options";
import { debug, blockRefreshTag } from "../constants";
import { convertToArray } from "../utils/to-array";
import { getHattieConfig } from "../vendors/hattie";
import { getDeviceType, DevicesEnum } from "../utils/device";
import { abtInRange } from "../experiments/constants";

const IAB_SIZES = ["728x90", "970x250", "300x600", "300x250", "320x50"];
const ALLOWED_FORMATS = ["injector", "rrail"];

/**
 * List of ads that we should be able to refresh
 */
const eligibleAds = [];

// Prevent the page from going crazy and racking up 100 refreshes
let refreshCount = 0;
let maxRefreshCount = 3;

if (getDeviceType() === DevicesEnum.desktop) {
  maxRefreshCount = 5;
}
let refreshIntervalHandle;

/**
 * In an SPA, we can do more refreshes after a new pageview
 */
export function resetRefreshCount() {
  refreshCount = 0;
}

/**
 * @param {GptAd} ad
 * @returns {Boolean} Is this an IAB sized ad?
 */
function isIAB(ad) {
  return !!ad.size && IAB_SIZES.indexOf(ad.size.join("x")) !== -1;
}

/**
 * @param {GptAd} ad
 * @return {Boolean} Can this format be refreshed?
 */
function isAllowedFormat(ad) {
  return ALLOWED_FORMATS.indexOf(ad.data.format) !== -1;
}

/**
 * @param {GptAd} ad
 * @returns {Boolean} Is this really an IAB sized ad?
 */
function isLegitHeight(ad) {
  // check if ad height is what is says it is
  const adFrame = ad.element.getElementsByTagName("iframe")[0];
  const frameHeight = adFrame.clientHeight;

  return !!ad.size && ad.size[1] === frameHeight;
}

/**
 *
 * @param {GptAd} ad
 */
function isBlockedByHattie(ad) {
  const disableAdRefresh = getHattieConfig().disableAdRefresh;

  // If we can't be sure, don't refresh
  if (!disableAdRefresh) {
    return false;
  }

  if (disableAdRefresh.line_items.indexOf(ad.lineItemId) !== -1) {
    log(`🎩 Line item ${ad.lineItemId} is a sponsorship`);
    return true;
  }

  if (disableAdRefresh.orders.indexOf(ad.campaignId) !== -1) {
    log(`🎩 Order ${ad.campaignId} is a sponsorship`);
    return true;
  }
  return false;
}

/**
 * @param {GptAd} ad
 * @returns {Boolean} Is this ad allowed to refresh
 */
function isRefreshEligible(ad) {
  const isNotAdX = !!ad.creativeId;

  // Must use the sizes-at syntax
  const isModernAPI =
    convertToArray(ad.element.attributes).filter((attr) => {
      return attr.name.indexOf("sizes-at-") !== -1;
    }).length > 0;

  return (
    ad.loaded &&
    ad.hadViewableImpression &&
    isNotAdX &&
    isIAB(ad) &&
    isAllowedFormat(ad) &&
    !isBlockedByHattie(ad) &&
    isModernAPI &&
    isLegitHeight(ad)
  );
}

/**
 * Changes the GPT element, causing it to refresh
 * at the same size without jank.
 *
 * @param {GptAd} ad
 */
function updateGptAdElementForRefresh(ad) {
  ad.element.setAttribute("targeting-refresh", "1");

  // Set the current size to be the only eligible size at this breakpoint
  const breakpointWidth = ad.getActiveBreakpoint();
  ad.element.setAttribute(`sizes-at-${breakpointWidth}`, JSON.stringify([ad.size]));

  // Lock the dimensions so it won't jump
  const previousHeight = ad.element.style.height;
  ad.element.style.height = `${ad.element.getBoundingClientRect().height}px`;

  // As soon as the ad loads, put it back the way it was
  ad.element.addEventListener(
    "ad-loaded",
    (e) => {
      ad.element.style.height = previousHeight;
    },
    { once: true }
  );

  // Finally, we "forget" this ad by removing it's ID and taking it out
  // of the list of registered ads.
  //
  // The scanner will find it again, say "who is this?" and run it through
  // the bidding and lazy-loading lifecycle as if it were a new ad.
  ad.unregister();
}

function findAdsToRefresh() {
  // Never refresh out of view
  if (document.visibilityState === "hidden") {
    return;
  }

  // Or if the bookmarklet is open
  if (document.getElementById("adops-dashboard")) {
    return;
  }

  if (eligibleAds.length) {
    eligibleAds
      .filter((ad) => {
        // Don't waste refreshes on ads that are far offscreen
        return ad.inView;
      })
      .forEach((ad) => {
        if (refreshCount >= maxRefreshCount) {
          log(
            `Refreshing ads stopped. We've reached the maximum ${maxRefreshCount} ads per pageview.`
          );
          clearInterval(refreshIntervalHandle);
          return;
        }

        log(`⌛️ We will refresh ${ad.id}!`);
        eligibleAds.splice(eligibleAds.indexOf(ad), 1);
        updateGptAdElementForRefresh(ad);
        refreshCount++;
      });
  }
}

export default function setupRefresh() {
  // Refresh is true by default
  if (getOptions().refresh === false) {
    return;
  }

  window.addEventListener("viewable-impression", (e) => {
    if (!e || !e.target) {
      return;
    }
    const ad = GptAd.getById(e.target.id);

    // When testing, we can use a shorter refresh
    const delay = 30000;

    // Filter out any ads that we can never refresh
    if (isRefreshEligible(ad)) {
      if (refreshCount < maxRefreshCount) {
        log(`⏳ We can refresh ${ad.id} in ${delay / 1000}s`);
      }
      setTimeout(() => {
        eligibleAds.push(ad);
      }, delay);
    }
  });

  refreshIntervalHandle = setInterval(findAdsToRefresh, 5000);
}
