// @ts-check
let pageCount = 0;

export function incrementPageCount() {
  pageCount++;
}

export function getPageCount() {
  return pageCount;
}
