// @ts-check
import { log, logError } from "../../utils/log";
import { getDeviceType } from "../../utils/device";
import { getJwtData, isSubscriber, isLoggedIn } from "../../utils/jwt";
import { getGlobals } from "../../options";

// Carbon docs are stored at
// https://atlanticmedia.atlassian.net/wiki/spaces/PEG/pages/1936064513/First+Party+Party

/**
 * @typedef {Object} CarbonAudienceEvent
 * @property {String} id - UUID
 * @property {String} Label - Name the event
 * @property {String} [StringKey] - Key for string-based metadata
 * @property {String} [StringValue] - Value for string-based metadata
 * @property {String} [NumberKey] - Key for number-based metadata
 * @property {Number} [NumberValue] - Value for string-based metadata
 * @property {Number} [Money] - Revenue associated with this event
 */

/**
 * @typedef {Object} PixelData
 * @property {string[]} simple
 * @property {CarbonAudienceEvent[]} detailed
 */

export const pixels = {
  sponsorContentReader: "d540f76c-7624-4ddf-a28b-aab431972caf",
  kruxAudience: "716a40d6-b926-49fc-aa97-30e6d733afad",
  author: "d1fc15d1-3a48-4a17-b38c-577641fb9968",
  category: "7e9c5fe0-0481-4eb3-8526-64d1b9a378b3",
  report: "9d06808e-e384-4013-b50c-c43b63af1fe7",
  device: "2e62e623-5453-4ec2-b688-693e45fbc14e",
  referrer: "7dfb3bab-6b2c-45a2-8ae3-75ad010370d8",
  articleID: "7c352d50-3d52-4c90-8a22-3ef051b3460b",
  channels: "67f74e02-f3cc-4a04-b0a4-f11a7c09c8f1",
  podcast: "4bac1eb9-b0c5-468c-addf-4f1149b2f1a5",
  subonlynewsletter: "ca0a02bf-c95c-4d7c-b534-9e65ebdf267a",
  registeredUser: "56f7e121-2225-4297-8a57-72a868385500",
};

/**
 * Triggers a simple event with no key/values
 * @param {String} uuid
 */
function simpleEvent(uuid) {
  window.cca = window["cca"] || {};
  var cca = window.cca;
  cca.privacy = cca.privacy || { que: [] };
  cca.privacy.que.push(function () {
    cca.fireAudienceEvent(uuid);
  });
}

/**
 * Record audience data with key/values
 * @param {CarbonAudienceEvent | CarbonAudienceEvent[]} data
 */
function detailedEvent(data) {
  window.ccao = window["ccao"] || {};
  var ccao = window.ccao;
  ccao.privacy = ccao.privacy || { que: [] };
  ccao.privacy.que.push(function () {
    ccao.fireTrackingEvent(data);
  });
}

/**
 * This will contain all custom pixel-firing logic.
 * Break it up as it grows.
 * @returns (Object)
 */
export function getPixelData() {
  /** @type PixelData */
  const results = {
    simple: [],
    detailed: [],
  };
  // Get the datalayer page object
  const dataLayerPageObj = window.dataLayer?.find((dl) => dl.event === "pageview")?.page;
  const getGlobalsObj = getGlobals();
  const pageInfoObj = window.Atlantic?.page_info;
  const isGhost = !!window.GhostContentAPI;

  // Mark sponsor content readers
  if (pageInfoObj?.is_sponsored) {
    log("Carbon Event: Sponsor Content Reader");
    results.simple.push(pixels.sponsorContentReader);
  }

  // Mark each article author
  if (dataLayerPageObj?.article?.authors) {
    let authors = dataLayerPageObj?.article?.authors.map((author) => ({
      id: pixels.author,
      Label: "Author Reader",
      StringKey: "Author",
      StringValue: author.displayName,
    }));
    log("Carbon Event: Author Read");
    results.detailed.push(...authors);
  }

  // Mark each article category
  if (dataLayerPageObj?.article?.categories) {
    let categories = dataLayerPageObj?.article?.categories.map((cat) => ({
      id: pixels.category,
      Label: "Category Reader",
      StringKey: "Category",
      StringValue: cat.slug,
    }));
    log("Carbon Event: Category Read");
    results.detailed.push(...categories);
  } else if (pageInfoObj?.categories) {
    let categories = pageInfoObj?.categories.map((cat) => ({
      id: pixels.category,
      Label: "Category Reader",
      StringKey: "Category",
      StringValue: cat,
    }));
    log("Carbon Event: Category Read");
    results.detailed.push(...categories);
  }

  // Mark the device used
  let deviceInfo = {
    id: pixels.device,
    Label: "Device Used",
    StringKey: "Device",
    StringValue: getDeviceType(),
  };
  log("Carbon Event: Device");
  results.detailed.push(deviceInfo);

  // Mark the referrer
  let referrerInfo = {
    id: pixels.referrer,
    Label: "Referred By",
    StringKey: "Referrer",
    StringValue: document.referrer,
  };
  log("Carbon Event: Referred");
  results.detailed.push(referrerInfo);

  // Mark the articleID
  if (getGlobalsObj?.id) {
    let articleId = {
      id: pixels.articleID,
      Label: "Article ID",
      StringKey: "ArticleId",
      StringValue: getGlobalsObj?.id,
    };
    log("Carbon Event: Has Article ID");
    results.detailed.push(articleId);
  }

  // Mark the channels
  if (dataLayerPageObj?.article?.channels) {
    let channelInfo = dataLayerPageObj?.article?.channels?.map((channel) => ({
      id: pixels.channels,
      Label: "Channels",
      StringKey: "Channel",
      StringValue: channel.slug,
    }));
    log("Carbon Event: Has Channels", channelInfo);
    results.detailed.push(...channelInfo);
  }

  // Mark if it's a podcast
  if (pageInfoObj?.channels?.includes("podcasts")) {
    log("Carbon Event: Podcast Reader");
    results.simple.push(pixels.podcast);
  }

  // Mark if it's a special project
  if (getGlobalsObj?.report) {
    let report = {
      id: pixels.articleID,
      Label: "Report Name",
      StringKey: "Report",
      StringValue: getGlobalsObj?.report,
    };
    log("Carbon Event: Special Project Reader");
    results.detailed.push(report);
  }

  // Mark sub-only newsletters
  if (isGhost) {
    // Get the site name and title
    const newsletterNameEl = document.querySelector("meta[property='og:site_name']");
    if (!newsletterNameEl) {
      logError("Newsletter name element not found");
    }
    let newsletterName = newsletterNameEl ? newsletterNameEl.getAttribute("content") : "";
    let newsletterTitle = document.title;
    // If these are the same the reader is on a landing page and we don't need the title
    let stringValue =
      newsletterName === newsletterTitle
        ? newsletterName
        : newsletterName + " – " + newsletterTitle;

    let son = {
      id: pixels.subonlynewsletter,
      Label: "Sub Only Newsletter",
      StringKey: "Newsletter",
      StringValue: stringValue,
    };
    log("Carbon Event: Sub Only Newsletter Reader");
    results.detailed.push(son);
  }

  // Mark if logged in and get subscriber status
  if (isLoggedIn()) {
    let status = {
      id: pixels.registeredUser,
      Label: "Registered User",
      StringKey: "Subscription",
      StringValue: `${isSubscriber() ? "paymeter_access" : "none"}${
        getJwtData().premium_access ? ", premium_access" : ""
      }`,
    };
    log(
      `Carbon Event: Registered and ${
        isSubscriber() ? "Subscribed" : "Not Subscribed"
      } Reader`
    );
    results.detailed.push(status);
  }

  return results;
}

export function triggerPixels() {
  const { simple, detailed } = getPixelData();
  simple.forEach((uuid) => {
    simpleEvent(uuid);
  });
  detailedEvent(detailed);
}
