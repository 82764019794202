// @ts-check
// Official list of sizes
export const sizes = {
  native: [1, 2],
  logo: [88, 31],
  leaderboard: [728, 90],
  billboard: [970, 250],
  halfpage: [300, 600],
  box: [300, 250],
  mobilebanner: [320, 50],
  custom: [1, 1],
  responsive: [1, 3],
  videoUpNext: [2, 2],
  cineflex: [2, 4],
  house: [3, 3],
  high_priority_blocker: [4, 4], // Runs a sponsor-level blocker pixel to keep the ros stuff out
};

/**
 * Official list of named size groups
 */
export const sizeDefinitions = {
  // Native ads
  native: [sizes.native],

  // House ads
  house: [sizes.house],

  // Sizes that span the full width
  // of the page on desktop
  "mobile-wide": [sizes.responsive, sizes.box],

  // Sizes that span the full width
  // of the page on desktop
  "desktop-wide": [sizes.responsive, sizes.leaderboard, sizes.billboard],

  // Fits in a right rail, desktop only
  rail: [sizes.box, sizes.halfpage],

  // Just a box
  box: [sizes.box],

  cineflex: [sizes.cineflex],

  logo: [sizes.logo],

  custom: [sizes.custom],

  leaderboard: [sizes.leaderboard, sizes.billboard, sizes.high_priority_blocker],
};
