// @ts-check
/** @typedef {import("../utils/types")}  */
/** @typedef {import("../utils/types").PermutiveEvent} PermutiveEvent */
/** @typedef {import("../utils/types").PermutivePageview} PermutivePageview */
import { isGDPRCmp, checkCMPPermissions, allowFirstPartyTracking } from "../consent";
import { loadScript } from "../utils/load";
import { isLoggedIn, getJwtData } from "../utils/jwt";
import { log } from "../utils/log";
import { getGlobals, getOptions } from "../options";

/** @type {PermutiveEvent[]} stores events before Permutive initializes */
const initialEventQueue = [];

/** @see https://support.permutive.com/hc/en-us/articles/8239608236956 */
const ORGANIZATION_ID = "714aecbf-a3b6-4f4e-8006-c57c6507930d";
const WORKSPACE_ID = "714aecbf-a3b6-4f4e-8006-c57c6507930d";
const WORKSPACE_API_KEY = "dd8a33a7-9354-467a-b1e8-810568eb2d02";

// GDPR Vendor IDs
const PERMUTIVE_LTD_ID = "361";
const PERMUTIVE_TECHNOLOGIES_ID = "360";

/**
 * Permutive counts as third-party data collection, even though we are only storing first-party data
 * ✅ script placed before a call to window.googletag.enableServices()
 * @see https://support.permutive.com/hc/en-us/articles/360011779239-Deploying-the-Permutive-JavaScript-Tag
 * @see https://support.permutive.com/hc/en-us/articles/8239608236956-The-Atlantic-Web-Deployment
 */
export default function permutive(resolve) {
  // time out permutive if it doesn't load
  setTimeout(() => resolve("timeout"), 750);
  if (!allowFirstPartyTracking()) {
    return resolve();
  }
  permutiveSetup();
  const script = loadScript(
    `https://${ORGANIZATION_ID}.edge.permutive.app/${WORKSPACE_ID}-web.js`,
    "permutive",
    resolve
  );
  if (script) {
    script.onload = resolve;
  }
  checkPermutiveConsent().then((consentGranted) => {
    localStorage.setItem("permutive-consent", consentGranted);
    window.permutive.consent({
      opt_in: consentGranted,
      token: "CONSENT_GRANTED",
    });
  });

  /**
   * There is some GAM work to finish setting this up
   * https://support.permutive.com/hc/en-us/articles/360010226039-Google-Ad-Manager-Integration
   */
  gamIntegration();
  registerUser();

  const dataLayerArticle = window.dataLayer?.find((dl) => dl.event === "pageview")?.page
    ?.article;

  /** @type PermutivePageview     */
  const pageviewData = {
    page: {
      created_at: dataLayerArticle?.datePublished,
      projects: dataLayerArticle?.editorialProject?.slug,
      page_type: getOptions().zone?.split("/")[3] || "unknown",
      categories: dataLayerArticle?.categories?.map((cat) => cat.slug),
      language: window.navigator.language,
      user: {
        ad_blocker: !!window.__ads?.adsBlocked,
        ad_free: getJwtData().ad_free,
        logged_in: isLoggedIn(),
        subscription: getJwtData().paymeter_access,
      },
      channels: dataLayerArticle?.channels?.map((channel) => channel.slug),
      article: {
        authors: dataLayerArticle?.authors.map((a) => a.displayName),
        title: dataLayerArticle?.title,
      },
      tags: getGlobals().tags,
    },
  };
  window.permutive.addon("web", pageviewData);

  // Handle any initial events that were queued before Permutive loaded
  initialEventQueue.forEach((event) => {
    trackPermutiveEvent(event);
  });
  initialEventQueue.length = 0;
}

/** This checks if the vendor was selected as having consent in the CMP */
function checkPermutiveConsent() {
  if (!isGDPRCmp()) {
    return Promise.resolve(true);
  }
  return new Promise((resolve) => {
    window.__tcfapi("getTCData", 2, function (data) {
      const { vendor } = data;
      const hasVendorConsent = checkCMPPermissions(vendor.consents, [
        PERMUTIVE_LTD_ID,
        PERMUTIVE_TECHNOLOGIES_ID,
      ]);

      // Knows whether we can load permutive
      resolve(hasVendorConsent);
    });
  });
}

/**
 * Code from Permutive dev docs
 */
function permutiveSetup() {
  // @ts-ignore
  const crypto = window.crypto || window.msCrypto;
  // @ts-ignore
  !(function (e, o, n, i) {
    if (!e) {
      (e = e || {}), (window.permutive = e), (e.q = []);
      var t = function () {
        // @ts-ignore
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (e) {
          return (
            e ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (e / 4)))
          ).toString(16);
        });
      };
      (e.config = i || {}),
        (e.config.apiKey = o),
        (e.config.workspaceId = n),
        (e.config.environment = e.config.environment || "production"),
        crypto && (e.config.viewId = t());
      for (
        var g = [
            "addon",
            "identify",
            "track",
            "trigger",
            "query",
            "segment",
            "segments",
            "ready",
            "on",
            "once",
            "user",
            "consent",
          ],
          r = 0;
        r < g.length;
        r++
      ) {
        var w = g[r];
        e[w] = (function (o) {
          return function () {
            var n = Array.prototype.slice.call(arguments, 0);
            e.q.push({ functionName: o, arguments: n });
          };
        })(w);
      }
    }
  })(window.permutive, WORKSPACE_API_KEY, WORKSPACE_ID, { consentRequired: true });
  window.googletag.cmd.push(function () {
    if (0 === window.googletag.pubads().getTargeting("permutive").length) {
      var e = window.localStorage.getItem("_pdfps");
      window.googletag.pubads().setTargeting("permutive", e ? JSON.parse(e) : []);
      var o = window.localStorage.getItem("permutive-id");
      o &&
        (window.googletag.pubads().setTargeting("puid", o),
        window.googletag.pubads().setTargeting("ptime", Date.now().toString())),
        window.permutive.config.viewId &&
          window.googletag
            .pubads()
            .setTargeting("prmtvvid", window.permutive.config.viewId),
        window.permutive.config.workspaceId &&
          window.googletag
            .pubads()
            .setTargeting("prmtvwid", window.permutive.config.workspaceId);
    }
  });
}

/**
 * Reads Permutive data from the browser and ensures it is passed into GAM
 **/

function gamIntegration() {
  window.googletag.cmd.push(function () {
    /**
     * Reads Permutive targeting data from the browser cache and ensures it is passed into GAM
     */
    if (window.googletag.pubads().getTargeting("permutive").length === 0) {
      var kvs = window.localStorage.getItem("_pdfps");
      window.googletag.pubads().setTargeting("permutive", kvs ? JSON.parse(kvs) : []);
    }
    /**
     * Use Permutive ID from local storage and set as PPID in GAM
     */
    const permutiveId = window.localStorage.getItem("permutive-id") || "";
    window.googletag.pubads().setPublisherProvidedId(permutiveId);
  });
}

function registerUser() {
  if (!isLoggedIn()) return;
  // @ts-ignore
  const { uuid } = getJwtData();
  window.permutive.identify([
    {
      id: uuid,
      tag: "janrain_uuid",
      priority: 0,
    },
  ]);
}

/**
 * @param {PermutiveEvent} event
 * @return {Promise<true|unknown[]>}  resolves with success (true) or error array
 */
export function trackPermutiveEvent(event) {
  if (!window.permutive || !window.permutive.track) {
    initialEventQueue.push(event);
    return new Promise((resolve) => resolve(["not ready yet"]));
  }

  return new Promise((resolve, reject) => {
    window.permutive.ready(() => {
      let opt = {
        success: function (result) {
          log("Permutive Event: " + result.name);
          resolve(true);
        },
        error: function (errors) {
          reject(errors);
        },
      };

      const data = { ...event, id: undefined };
      window.permutive.track(event.id, data, opt);
    });
  });
}

/**
 * Segments can be created in dashboard with the Segment Builder
 * @param {number} segmentCode
 * @returns if the user is in the segment
 */
export function getPermutiveSegment(segmentCode) {
  return new Promise((resolve) => {
    window.permutive.segment(segmentCode, function (result) {
      resolve(result);
    });
  });
}

/**
 * @return {Promise<{[idx: number]: boolean}>} a map of segment ids to whether the user is in the segment
 */
export function getAllPermutiveSegments() {
  return new Promise((resolve) => {
    return window.permutive.segments((segments) => {
      resolve(segments);
    });
  });
}
