// @ts-check
/** @typedef {import("./models").GptAd} GptAd  */

import { performance } from "./utils/perf";
import { log } from "./utils/log";
import { inLazyRange, getLoadableAds } from "./lazyload";
import { lazyLoadQueue } from "./queues";
import { getOutOfPageUnit } from "./outofpage";
import { getOptions } from "./options";
import { getFormatCounts } from "./utils/counts";
import { getDeviceType } from "./utils/device";
import { getHattieConfig } from "./vendors/hattie";
import { experiments } from "./experiments/constants";
import BidStore from "./bidstore";

// Cached scroll position
let scrollPosition = window.pageYOffset;

// Track the number of refreshes so we know
// which batch an ad is in.
let refreshCount = 0;

// For perf markers
// Native and house ads don't need to wait on lazy bidding.
// Track these independently.
let has_called = false;
let has_called_with_tasks = false;

/**
 * @param {GptAd} ad
 * @param {string[]} bidders
 */
function addNobidAndHighbid(ad, bidders) {
  // Fetch variable market data from Hattie.
  // If Hattie is unreachable or returns empty data, use defaults.
  const marketData = getHattieConfig().marketData;
  let nobidThreshold = 0.5;
  let highbidThreshold;

  if (marketData && Object.keys(marketData).length) {
    const device = getDeviceType();
    const highbidThresholdKeys = {
      mobile: "highbidThresholdMobile",
      tablet: "highbidThresholdTablet",
      desktop: "highbidThresholdDesktop",
    };
    const nobidThresholdKeys = {
      mobile: "nobidThresholdMobile",
      tablet: "nobidThresholdTablet",
      desktop: "nobidThresholdDesktop",
    };

    highbidThreshold = marketData[highbidThresholdKeys[device]];
    nobidThreshold = Math.max(nobidThreshold, marketData[nobidThresholdKeys[device]]);
  }

  // Pass DFP a custom targeting boolean asserting
  // whether the ad's bids are under the nobid threshold
  const maxBid = ad.getMaxBid();
  let has_nobids =
    bidders.length === 0 || (maxBid !== undefined && maxBid <= nobidThreshold);
  // AB test to see how nobid affects ad allocation
  if (experiments.mutedNobid()) {
    // @ts-ignore
    has_nobids = has_nobids ? "mutedtrue" : "mutedfalse";
  }

  ad.getGptSlot().setTargeting("nobids", has_nobids.toString());
  // Send the boolean to the bookmarklet
  ad.data.targeting.nobids = has_nobids;

  // Pass DFP a custom targeting boolean asserting
  // whether the ad's bids are over the highbid threshold
  let has_highbid = false;
  if (bidders.length !== 0 && highbidThreshold) {
    has_highbid = maxBid !== undefined && maxBid >= highbidThreshold;
  }
  if (experiments.mutedHighbid()) {
    // @ts-ignore
    has_highbid = has_highbid ? "mutedtrue" : "mutedfalse";
  }
  ad.getGptSlot().setTargeting("highbid", has_highbid.toString());
  ad.data.targeting.highbid = has_highbid;

  // Prevent highbid programmatic lines from beating test key-values
  if (googletag.pubads().getTargeting("test").length) {
    ad.getGptSlot().setTargeting("highbid", "false");
    ad.data.targeting.highbid = false;
  }
}

export function render(options) {
  // Update default options.
  let opt = Object.assign(
    {
      // The Correlator is a unique id for the pageview
      // in GPT. Keeping it the same tells the server
      // "this ad call is for the same page" and
      // enforces competitive separation.
      changeCorrelator: refreshCount ? false : true,
      defaultLazy: getOptions().lazy_load || 0,
    },
    options
  );

  // If the user is scrolling faster than 500px per quarter
  // second there's no way they'll see any ads - abort.
  let _scrolled = Math.abs(scrollPosition - window.pageYOffset);

  // Update the cached scroll position
  scrollPosition = window.pageYOffset;

  if (_scrolled > 500) {
    return;
  }

  let gptAds = getLoadableAds();

  // Run the lazy-loading queue
  lazyLoadQueue.execute(function (fn) {
    fn();
  });

  gptAds = gptAds.filter((ad) => {
    const lazy = ad.getLazySetting();

    // Filter the lazy ads by whether they're in range.
    const inRange = inLazyRange(ad.element, lazy);
    return inRange;
  });

  // These were the ads we attempt to load in the very first
  // batch before we knew whether they were locked etc.
  if (refreshCount === 0) {
    gptAds.forEach((ad) => {
      ad.inLazyRangeAtPageLoad = true;
    });
  }

  // This comes after the in-range check so we can track
  // how often an ad "stalls," or doesn't load because it's
  // waiting on tasks to complete.
  gptAds = gptAds.filter((ad) => {
    const ready = ad.isTaskQueueComplete();
    if (!ready) {
      const taskNames = ad.tasks
        .filter((t) => !t.isComplete)
        .map((t) => t.name)
        .join(", ");
      log(`${ad.element.id} stalled waiting on ${taskNames} 🛑`);
      ad.stalls++;
    }
    return ready;
  });

  let slots = gptAds.map((ad) => ad.getGptSlot());

  const outOfPageAd = getOutOfPageUnit();
  if (outOfPageAd) {
    slots.push(outOfPageAd);
  }

  // No GPT ads to render
  // This includes the outofpage, which isn't a GptAd instance
  if (slots.length === 0) {
    return;
  }

  // Increment refresh count
  refreshCount++;

  const counts = getFormatCounts();

  gptAds.forEach(function (ad) {
    ad.setCalled();
    ad.batch = refreshCount;

    // Bids
    const bidders = ad.getBidders();

    // Add nobid and highbid targeting to DFP
    addNobidAndHighbid(ad, bidders);

    // If there were bids, send the highest bid,
    // rounded up to the nearest floor, to DFP

    // Make maxBid and secondHighestBid 0 if there is none or undefined for Native/Custom
    const maxBid = ad.tasks.length ? ad.getMaxBid() || 0 : undefined;
    const maxBidStr = typeof maxBid === "number" ? maxBid.toFixed(2) : undefined;
    const secondHighestBid = ad.tasks.length
      ? (ad.getSecondHighestBid() || "0.00").toString()
      : undefined;

    ad.data.targeting.maxbid = maxBidStr;
    // @ts-ignore this functions just fine setting maxBidStr to undefined
    ad.getGptSlot().setTargeting("maxbid", maxBidStr);

    ad.data.targeting.secbid = secondHighestBid;
    ad.getGptSlot().setTargeting("secbid", secondHighestBid);

    // log result
    if (bidders.length) {
      log(
        `Ads: ${ad.id} called with the bidders from \n  ${bidders.join("\n  ")}`,
        "\nTargeting : ",
        ad.getTargetingMap()
      );
    } else {
      log(`Ads: ${ad.id} called with 0 bids`);
    }

    // Set injector count targeting
    for (let key in counts) {
      ad.getGptSlot().setTargeting(key, counts[key]);
    }

    // Keep the targeting at the time we make the call
    ad.recordTargeting();
  });

  log("GPT Refresh call:", {
    ads: gptAds,
    changeCorrelator: opt.changeCorrelator,
  });

  if (!has_called) {
    performance.mark("ads:first_call");
    has_called = true;
  }

  if (!has_called_with_tasks && gptAds.filter((ad) => ad.tasks.length).length) {
    performance.mark("ads:first_call_with_locking_tasks");
    has_called_with_tasks = true;
  }

  // Make the call to DFP
  // Fetch and display new ads for the given slots
  window.onDvtagReady(function () {
    googletag.pubads().refresh(slots, {
      changeCorrelator: opt.changeCorrelator,
    });
  });

  // Record the bids we used to call these ad
  gptAds.map((gptAd) => BidStore.recordBids(gptAd));
}
