import { dispatch } from "./event";
import { log } from "./log";

export function dispatchHasAdblock() {
  log(`Adblock detected`);
  if (window.__ads?.loaded) {
    window.__ads.adblock = true;
  }
  dispatch("ads-blocked", window);
}
