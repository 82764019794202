// @ts-check
import constants from "./constants";
import referrers from "./referrers";
import {
  gptDomainOverride,
  setRandomValue,
  setCDNABTestingValue,
  pushDataToGTM,
} from "./testing";
import utmSource from "./utmsource";
import timing from "./timing";
import patchEventHandlers from "./safety";
import doubleVerifyReady from "./doubleverify";
import keyValueOverride from "./key-value-override";
import pageMeta from "./page-meta";
import chromeExperiments from "./chrome-experiments";
import sessionDepth from "./session-depth";
import measurementHelpers from "./measurement-helpers";
import loadBookmarklet from "./load-bookmarklet";
import user from "./user";
import privacy from "./privacy";
import templates from "./templates";
import setupRefresh from "./refresh";

const mixins = {
  constants,
  referrers,
  gptDomainOverride,
  utmSource,
  timing,
  patchEventHandlers,
  setRandomValue,
  doubleVerifyReady,
  keyValueOverride,
  pageMeta,
  chromeExperiments,
  sessionDepth,
  measurementHelpers,
  loadBookmarklet,
  user,
  privacy,
  templates,
  setupRefresh,
  setCDNABTestingValue,
  pushDataToGTM,
};

export default mixins;
