// @ts-check
/**
 * Strips spaces after newlines. Used for keeping
 * multiline template strings clean.
 * @param  {String} str   Text to strip.
 * @return {String}       Stripped text.
 */
export function stripIndent(str) {
  return str.replace(/\n\s+/g, "\n");
}
