// @ts-check
/** @typedef {import("../utils/types").PromiseResolve} PromiseResolve  */
import { loadScript } from "../utils/load";
import { log } from "../utils/log";
import { debug } from "../constants";
import { getOptions } from "../options";
import { getDeviceType } from "../utils/device";

const desktopUrl = "//cadmus.script.ac/dojx47ab4dyxi/script.js";
const mobileUrl = "//cadmus.script.ac/d1132y4a2t9kc9/script.js";

// We don't want to load this twice
let ranOnce = false;

/**
 * Loads bad-ad monitoring vendor
 *
 * @param {PromiseResolve}  resolve  Call when done.
 */
export default function cleanCreatives(resolve) {
  // Disable if no programmatic
  const { prebid, amazon } = getOptions();
  const hasProgrammatic = !!(prebid || amazon);
  const isDesktop = getDeviceType() === "desktop";
  // Temporary: Mobile url doesn't seem to be working right now.
  let url = desktopUrl;

  if (debug["disable-cleanio"] || ranOnce) {
    resolve();
    return;
  }

  ranOnce = true;

  if (!hasProgrammatic) {
    resolve();
    return;
  }

  log("Loading Clean Creatives");

  const script = loadScript(url, "clean-creatives", resolve);
  if (script) {
    script.onload = resolve;
  }

  setTimeout(() => resolve("timeout"), 5000);
}
