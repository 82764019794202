// @ts-check

/**
 * Loads a tracking pixel.
 *
 * @param      {String}  src     The src of the img to load.
 */
export function loadPixel(src) {
  const img = document.createElement("img");
  img.src = src;
  img.width = 1;
  img.height = 1;
  img.setAttribute(
    "style",
    "position: absolute; top: -1px; left: -1px; " +
      "width:0; height:0; visibility:hidden;"
  );
  document.body.appendChild(img);
}

// Scripts we have already loaded on the page
const loadedScripts = [];

/**
 * Loads a script
 *
 * @param      {String}  src          The src of the third part script.
 * @param      {String}  name       The name of the script, for perf tracking
 * @param      {(value: any) => void} [onerror]  Function to call if it fails.
 *
 * @return     {HTMLScriptElement | void}  The script tag
 */
export function loadScript(src, name, onerror) {
  // check if we've already put it on the page
  if (loadedScripts.indexOf(src) !== -1) {
    return;
  }
  loadedScripts.push(src);

  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  script.addEventListener("load", () => performance.mark(`ads:${name}_loaded`));
  if (onerror !== undefined) {
    script.onerror = onerror;
  }
  document.body.appendChild(script);
  document.body.setAttribute(`data-${name}-loaded`, "true");
  return script;
}

export function loadAjax(url) {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.addEventListener("load", (e) => {
      try {
        resolve(JSON.parse(req.response));
      } catch (e) {
        reject();
      }
    });

    req.addEventListener("error", (e) => {
      reject();
    });

    req.open("GET", url);
    req.send();
  });
}
