// @ts-check
import { getOptions, setGlobals } from "../options";

/**
 * Sets default options for the template and template/raw values.
 *
 * Template is a nice name that we bucket multiple templates into
 * that describes the ad experience on the page.
 *
 * Template Raw should be the literal in-code name, preferrably
 * passed automatically, so if someone leaves the unknown default,
 * we can use that to figure out where and what it belongs to.
 *
 */
export default function templates() {
  const validTemplates = [
    "article_instant",
    "article_feature",
    "article_ghost",
    "article_photo",
    "article_standard",
    "article_twocol",
    "article_video",
    "homepage",
    "landing_author",
    "landing_channel",
    "landing_custom",
    "landing_ghost",
    "landing_standard",
    "other_crossword",
    "other_notes",
    "other_search",
    "other_unassigned",
    "sponsored",
  ];

  const options = getOptions();
  let template = options.zone ? options.zone.split("/")[4] : "";

  if (!validTemplates.includes(template)) {
    template = "unknown";
  }

  setGlobals({
    template: template || "unknown",
  });
}
