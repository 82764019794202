// @ts-check
import { random } from "../utils/random";
import { urlParams } from "../constants";
import { log } from "../utils/log";
import { getOptions } from "../options";

//
// There are two random numbers used to run
// AB tests and track the results in DFP.
//
let abtValue = undefined;
let syncValue = undefined;

export function getSyncValue() {
  if (syncValue === undefined) {
    syncValue = random(1, 100);
  }
  return syncValue;
}

/**
 * For new pageviews.
 */
export function resetRandomNumberKeyValues() {
  abtValue = undefined;
  syncValue = undefined;
}

/**
 * Gets the AB Test value.
 * Because ABT can be passed to the GPT Config, this
 * needs to be a function so its set the first
 * time its used instead of on runtime.
 *
 * @return     {number}  The abt value.
 */
export function getAbtValue() {
  if (abtValue === undefined) {
    abtValue =
      parseInt(urlParams.adkv_abt, 10) ||
      (getOptions().globals || {}).abt ||
      random(1, 100);
    log(`ABT value: ${abtValue}`);
  }
  return abtValue;
}

/**
 * Determines if ABT is in a given range, which tells us
 * if we should include this pageview an in experiment.
 *
 * @param      {number}   lowest   The lowest value to include
 * @param      {number}   highest  The highest value to include
 * @return     {boolean}  Is the ABT value between the highest and lowest
 */
export function abtInRange(lowest, highest) {
  return getAbtValue() >= lowest && getAbtValue() <= highest;
}

/**
 * When creating new experiments, update the Google Doc
 * https://docs.google.com/spreadsheets/d/1BVCn3692HE53Oqo4MZMJGq8wT5ZL_lrlfKBc7_XmDfs/edit#gid=0
 */
export const experiments = {};

experiments.mutedNobid = () => abtInRange(1, 1);
experiments.mutedHighbid = () => abtInRange(2, 2);
