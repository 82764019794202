// @ts-check

/**
 * Convert a non-array iterable, like an HTMLCollection,
 * to an array.
 * @param iterable
 * @returns Array
 */
export function convertToArray(iterable) {
  let items = [];
  for (let i = 0; i < iterable.length; i++) {
    items.push(iterable[i]);
  }
  return items;
}
