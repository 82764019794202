// @ts-check
import { gptQueue } from "../queues";
import { getOptions } from "../options";

const supportsPassive = function () {
  let supported = false;
  try {
    let opts = Object.defineProperty({}, "passive", {
      get: function () {
        supported = true;
      },
    });
    // @ts-ignore we are using a test event even if that doesn't exist in the browser
    window.addEventListener("test", null, opts);
  } catch (e) {}
  return supported;
};

//
// Patch addEvenListener for a window
// to prevent it from scrolljacking
// by patching all applicable events
// with `{passive: true}`
//
function blockNonPassiveEvents(win) {
  const _original = win.addEventListener;
  const passiveEvents = [
    "scroll",
    "dommousescroll",
    "mousewheel",
    "wheel",
    "touchmove",
    "touchstart",
    "mousemove",
  ];

  const safeAddEventListener = function (name) {
    let args = Array.prototype.slice.call(arguments);
    try {
      name = name.toLowerCase();

      let isPassive = args[2] && args[2].passive;
      if (!isPassive && passiveEvents.indexOf(name) > -1) {
        let options = typeof args[2] === "object" ? args[2] : {};
        if (typeof args[2] === "boolean") {
          options.useCapture = args[2];
        }
        options.passive = true;
        args[2] = options;
      }
    } catch (e) {}
    _original.apply(this, args);
  };

  // Attach to the top prototype for the window.
  // The effect will trickle down to all elements.
  win.EventTarget.prototype.addEventListener = safeAddEventListener;
}

export default function patchEventHandlers() {
  // If there's no browser support for passive
  // events, give it up.

  if (!getOptions().patchEventHandlers || !supportsPassive()) {
    return;
  }

  // Top level
  blockNonPassiveEvents(window);

  // All the ads' iframes
  gptQueue.push(function () {
    googletag.pubads().addEventListener("slotRenderEnded", function (event) {
      const id = event.slot.getSlotElementId();
      const el = document.getElementById(id);
      const iframes = el ? el.querySelectorAll("iframe") : [];
      for (let i = 0; i < iframes.length; i++) {
        blockNonPassiveEvents(iframes[i].contentWindow);
      }
    });
  });
}
