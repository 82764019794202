// @ts-check
// If the performance API isn't available
// pass noop functions.
const performance = window.performance || {};
performance.mark = performance.mark || function (str) {};
performance.measure = performance.measure || function (str1, str2, str3) {};
performance.now =
  performance.now ||
  /**
   * @returns {DOMHighResTimeStamp}
   */
  function () {
    return -1;
  };

export { performance };
