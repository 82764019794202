// @ts-check
/** @typedef {import("../utils/types").HattieConfig} HattieConfig  */

import { loadAjax } from "../utils/load";

const HATTIE_ENDPOINT = "https://www.theatlantic.com/sponsored/config/";

/**
 * @const {HattieConfig} stores response from hattie
 */
const hattieConfig = {};

export default function hattie(resolve) {
  loadAjax(`${HATTIE_ENDPOINT}?origin=${window.location.origin}`)
    .then((data) => {
      Object.assign(hattieConfig, data);
    })
    .catch((e) => {
      console.warn("Could not reach Hattie", e);
    })
    .finally(resolve);

  // This should be fast
  setTimeout(() => resolve("timeout"), 500);
}

/**
 * @returns {HattieConfig}
 */
export function getHattieConfig() {
  return hattieConfig;
}
