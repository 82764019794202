// @ts-check
import { getOptions } from "../../options";
import { triggerPixels } from "./pixels";
import { gptQueue } from "../../queues";
import { isLoggedIn, getJwtData } from "../../utils/jwt";
import { cookies } from "../../utils/cookies";
import { allowAllTracking } from "../../consent";
import { log, logError } from "../../utils/log";

// Atlantic's organization id
const orgId = "f38ac78416";

// Our core Carbon segments
const segments = [
  {
    name: "ATL 1P Core_US_IT Decision Makers",
    shortcode: "C_ITDM",
    id: "fc23b17b-50fe-4759-5217-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_Civically Charged",
    shortcode: "C_CIVIC",
    id: "f8f8b7bb-266b-49c1-5224-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_Education Leaders",
    shortcode: "C_EL",
    id: "f6b2b090-56b0-4f13-5226-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_Greenfluentials",
    shortcode: "C_Green",
    id: "d3fb08ea-f0b6-4d28-5227-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_Healthcare Influencers",
    shortcode: "C_HI",
    id: "b3fca21a-5acb-4307-5218-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_Business Decision Makers",
    shortcode: "C_BDM",
    id: "af08a452-cc46-49cf-521a-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_Buyers of the Best",
    shortcode: "C_Buy",
    id: "a3d3c328-f2d0-4743-521d-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_News Junkies",
    shortcode: "C_News",
    id: "a2f60f4d-eb06-4d2d-5225-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_Financial Planners",
    shortcode: "C_Fin",
    id: "96e4fd88-aff8-4d99-9b46-08d979f0de64",
  },
  {
    name: "ATL 1P Core_US_Drama Fanatics",
    shortcode: "C_Drama",
    id: "8a02edd9-c315-4a5e-5222-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_Small Business Owners",
    shortcode: "C_SBO",
    id: "84ce7da0-22ab-40bd-5219-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_Curious Documentarians",
    shortcode: "C_Docu",
    id: "80e2d2ac-2c8b-4458-5223-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_Always Driven",
    shortcode: "C_Driven",
    id: "7f0cbde4-b9aa-4b51-521c-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_Outdoor Actives",
    shortcode: "C_OA",
    id: "71805916-14cf-4d64-5221-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_Culture Cravers",
    shortcode: "C_Culture",
    id: "6e7cf89e-4d9e-47c0-5220-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_Pop Culture",
    shortcode: "C_POP",
    id: "6e1510a6-36a7-4de1-521e-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_Political Activists",
    shortcode: "C_Polic",
    id: "6c32a59b-e6b7-425a-522a-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_Government Officials",
    shortcode: "C_GOVOFC",
    id: "642b9853-9ec8-4f82-5228-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_Government Influencers",
    shortcode: "C_GOV",
    id: "5332a327-6d5d-4e06-5229-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_Young Disruptors",
    shortcode: "C_Young",
    id: "477ddc53-d88f-49d3-522b-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_C-Suite",
    shortcode: "C_CSUITE",
    id: "371cdc21-54c8-44a7-5216-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_Techfluentials",
    shortcode: "C_Tech",
    id: "291b0cb6-9663-4fbc-521b-08d9625777e5",
  },
  {
    name: "ATL 1P Core_US_Inspired Jetsetters",
    shortcode: "C_Jet",
    id: "1c2f4122-6bdc-4e4d-521f-08d9625777e5",
  },
];

/**
 * Primary Carbon script
 */
function mainTag() {
  // @ts-ignore
  !(function (a, l, b, c, k, s, t, g, A) {
    (a.CustomerConnectAnalytics = k),
      (a[k] =
        a[k] ||
        function () {
          (a[k].q = a[k].q || []).push(arguments);
        }),
      // @ts-ignore
      (g = l.createElement(b)),
      // @ts-ignore
      (A = l.getElementsByTagName(b)[0]),
      // @ts-ignore
      (g.type = "text/javascript"),
      // @ts-ignore
      (g.async = !0),
      // @ts-ignore
      (g.src = c + "?id=" + s + "&parentId=" + t),
      // @ts-ignore
      A.parentNode.insertBefore(g, A);
  })(
    window,
    document,
    "script",
    "https://carbon-cdn.ccgateway.net/script",
    "cca",
    window.location.hostname,
    orgId
  );
}

/**
 * Allows Carbon to capture Prebid data
 */
function revenueAnalyticsTag() {
  // @ts-ignore
  !(function (a, e) {
    (a.cca = a.cca || {}), (a.cca.tapPrebidQue = []), (a.cca.tapGAMQue = []);
    var n = a.cca;
    a.pbjs = a.pbjs || { que: [] };
    var t = a.pbjs;
    t.que.push(function () {
      t.onEvent("bidWon", function (a) {
        n.tapReady || n.tapPrebidQue.push(JSON.stringify(a));
      });
    }),
      (a.googletag = a.googletag || { cmd: [] });
    var c = a.googletag;
    c.cmd.push(function () {
      c.pubads().addEventListener("slotRenderEnded", function (a) {
        n.tapReady || n.tapGAMQue.push(a);
      });
    });
  })(window);
}

/**
 * Add targeting from local storage
 */
function setTargeting() {
  gptQueue.push(() => {
    // Vendor code
    if (googletag.pubads().getTargeting("carbon_segment").length === 0) {
      const jsonStr = window.localStorage.getItem("ccRealtimeData");
      const carbon = jsonStr ? JSON.parse(jsonStr) : false;
      googletag.pubads().setTargeting(
        "carbon_segment",
        carbon
          ? carbon.audiences.map(function (i) {
              return i.id;
            })
          : []
      );
    }
    if (googletag.pubads().getTargeting("cc-iab-class-id").length === 0) {
      const jsonStr = window.localStorage.getItem("ccContextualData");
      const iabIds = jsonStr ? JSON.parse(jsonStr) : false;
      if (iabIds) {
        googletag.pubads().setTargeting("cc-iab-class-id", iabIds);
      }
    }
  });
}

let alreadyLoaded = false;

/**
 * Gets the Carbon segment shortcodes for a given user
 * @returns {String} - Carbon segment shortcodes (comma-separated)
 */
export function getCarbonSegments() {
  if (!allowAllTracking()) {
    return "";
  }
  let segmentShortcodes = [];
  let carbonDataStr = window.localStorage.getItem("ccRealtimeData");
  let carbonData;
  try {
    carbonData = carbonDataStr ? JSON.parse(carbonDataStr) : false;
  } catch {
    logError(`Could not parse Carbon data: ${carbonData}`);
    return "";
  }
  if (carbonData) {
    const audienceIds = carbonData.audiences.map((i) => i.id);
    for (let i = 0; i < segments.length; i++) {
      if (audienceIds.includes(segments[i].id)) {
        segmentShortcodes.push(segments[i].shortcode);
      }
    }
  }
  return segmentShortcodes.join(",");
}

/**
 * Loads Carbon, our first party data platform
 */
export default function loadCarbon(resolve) {
  // Allow disabling this
  // Disable when we have non-personalized ads
  if (getOptions().carbon === false || !allowAllTracking()) {
    log("Do not load carbon");
    return resolve();
  }

  // Run this on all pageviews
  try {
    triggerPixels();
  } catch (e) {
    console.error("🚒 Error while firing Carbon pixels!");
    console.error(e);
  }
  setTargeting();

  // Since we're not using the loadScript function
  // we need to enforce this manually
  if (alreadyLoaded) {
    return resolve();
  }
  alreadyLoaded = true;

  mainTag();
  revenueAnalyticsTag();
  syncUser();

  // This is allowed to be a race condition.
  // Carbon's philosophy is "we should just be really, really fast and it won't be a problem"
  resolve();
}

/**
 * Ensure Carbon user profiles persist as long as possible
 * by passing a unique user identifier to a Carbon endpoint.
 * This ensures user syncing even if localStorage expires
 * or the user switches devices.
 */
function syncUser() {
  // Pass the GA cookie's UUID to Carbon
  fetch(`https://eus-api.ccgateway.net/v1/s/atlantic-gaid?puid=${cookies.get("_ga")}`, {
    credentials: "include",
  });
  if (isLoggedIn()) {
    // Pass the Janrain UUID to Carbon
    fetch(`https://eus-api.ccgateway.net/v1/s/atlantic-id?puid=${getJwtData().uuid}`, {
      credentials: "include",
    });
  }
}

/**
 * Run on new pageview
 */
export function resetCarbon() {
  if (!allowAllTracking()) {
    return;
  }
  if (window.carbonApi) {
    window.carbonApi.refreshCarbon();
  }
}
