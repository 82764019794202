// @ts-check
import { debug } from "../constants";

let measurementsCount = 0;

function logIfChanged() {
  const measurements = performance
    .getEntriesByType("measure")
    .filter(e => e.name.startsWith("ads:"));

  if (measurements.length === measurementsCount) {
    return;
  }

  measurementsCount = measurements.length;

  const data = {};
  measurements.forEach((measure) => {
    data[measure.name] = measure.duration;
  });
  console.debug(data);
}

/**
 * When ?debug=adperf, log the performance
 * measurements for easy access.
 */
export default function measurementHelpers() {
  if (debug.adperf) {
    window.addEventListener("ad-loaded", logIfChanged);
    window.addEventListener("ad-empty", logIfChanged);
  }
}
