// @ts-check

import { log } from "../utils/log";
import { setOptions, setGlobals } from "../options";
import { getJwtData } from "../utils/jwt";
import { allowAllTracking } from "../consent";

/**
 * Everything about the user from JWT etc.
 */
export default function user() {
  const { uuid, ad_free, premium_access, paymeter_access } = getJwtData();

  if (ad_free) {
    log(
      "💥 User has premium account. Targeting for house ads only and disabling header bidders."
    );
    // Disable header bidders
    setOptions({
      prebid: null,
      amazon: null,
    });
  }

  if (!uuid || !allowAllTracking()) {
    setGlobals({ user: "anonymous" });
  } else {
    // Set user value in DFP to match subscription status in GA
    let entitlements = "account-";
    if (premium_access || ad_free) {
      entitlements += "paymeter_access-premium_access";
    } else if (paymeter_access) {
      entitlements += "paymeter_access";
    }
    setGlobals({ user: entitlements });
  }
}
