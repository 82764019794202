// @ts-check

import { GptAd } from "./models";

/**
 * Determines if an element's client rectangle is close
 * enough to the viewport to lazyload
 *
 * @param      {Object}   arg1             The argument 1
 * @param      {Object}   arg1.clientRect  The client rectangle
 * @param      {number}   arg1.lazy        The lazy
 * @return     {boolean}  { description_of_the_return_value }
 */
export function elementInRange({ clientRect, lazy }) {
  const offset = clientRect.top; // Relative to top of viewport
  const upperBound = 0;
  const lowerBound = window.innerHeight * lazy;
  return offset < lowerBound && offset > upperBound;
}

//
// Elements with display:none have no offset.
// Search their siblings and parents until you
// find something visible, and use that offset
// as an approximation.
//
/**
 * @param {HTMLElement} el
 */
export function inferClientRect(el) {
  let sibling;

  /**
   * @param {HTMLElement} e
   */
  function positionedSibling(e) {
    let orig = e;
    while ((e = /** @type {HTMLElement} */ (e.previousElementSibling))) {
      if (e.offsetParent) {
        return e;
      }
    }
    e = orig;
    while ((e = /** @type {HTMLElement} */ (e.nextElementSibling))) {
      if (e.offsetParent) {
        return e;
      }
    }
  }

  while (el.offsetParent === null) {
    sibling = positionedSibling(el);
    if (sibling) {
      el = sibling;
    } else {
      el = /** @type {HTMLElement} */ (el.parentNode);
    }
  }

  return el.getBoundingClientRect();
}

/**
 * Get all ads that have not been called
 * or are deferred
 *
 * @return    {GptAd[]}   List of GptAd instances
 */
export function getLoadableAds() {
  return GptAd.all().filter((ad) => {
    return !ad.isDeferred() && !ad.called && document.body.contains(ad.element);
  });
}

/**
 * Determines if element is in range to lazy laod
 *
 * @param      {HTMLElement}   element  The element
 * @param      {Number}        lazy     How far from the viewport (in 100vh) should
 *                                      the ad start loading
 * @return     {Boolean}       To load or not to load?
 */
export function inLazyRange(element, lazy) {
  // Ads with lazy-load explicitly disabled
  if (lazy === 0) {
    return true;
  }

  return elementInRange({
    clientRect: inferClientRect(element),
    lazy: lazy,
  });
}
