// @ts-check
//
// This will allow something like
// `?adkv_custom-ad=cards` to set the key/value in the GPT call.
//

import { setGlobals } from "../options";
import { urlParams } from "../constants.js";

export default function keyValueOverride() {
  const customKeys = Object.keys(urlParams).filter((key) => {
    return key.indexOf("adkv_") === 0;
  });

  const self = this;

  customKeys.forEach((key) => {
    const globals = {};
    globals[key.replace("adkv_", "")] = urlParams[key].split(",");
    setGlobals(globals);
  });
}
