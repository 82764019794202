// @ts-check
import { setGlobals } from "../options";
import { floor } from "./../constants";

/*
 * Pass constants to the globals object for page-level targeting.
 */
export default function constants() {
  setGlobals({
    floor: floor.toFixed(2),
  });
}
