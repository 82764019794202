// @ts-check

import { uuid } from "./utils/uuid";

/**
 * unique id of the pageview, used for analytics.
 *
 * @type    {String|void}   UUID
 */
export const pageViewUuid = uuid();

/**
 * GET parameters
 */
export const urlParams = (() => {
  const params = {};
  const pairs = window.location.search.slice(1).toLowerCase().split("&");
  pairs.forEach((pair) => {
    let pairArr = pair.split("=");
    params[pairArr[0]] = pairArr[1] || true;
  });
  return params;
})();

export const debugDisableAds =
  urlParams["disable"] === "ads" && window.location.hostname !== "www.theatlantic.com";

/**
 * Is the current url the homepage?
 */
export const isPolitics = window.location.pathname.includes("/politics");

/**
 * List of functionality to debug
 *
 * @type {Object}
 *
 * Example:
 *
 *  {
 *    ads: true,
 *    prebid: true
 *  }
 *
 * Then code can check `if (debug.prebid)` etc.
 */
export const debug = (() => {
  const result = {};
  const params = window.location.search.slice(1).toLowerCase().split("&");
  params
    .map((param) => {
      return param.split("=");
    })
    .forEach((param) => {
      if (param[0] === "debug") {
        result[param[1]] = true;
      }
    });
  return result;
})();

/**
 * Human-readable names for ad creative sizes
 *
 * @type {Object}
 */
export const creativeSizeLabels = {
  "1x2": "native",
  "1x3": "responsive",
  "3x3": "house",
};

/**
 * Nonsense tag that tells the ad code nothing
 * goes here.
 * @type {String}
 */
export const blockPositionTag = '<meta name="atlads" content="block-unit">';

/**
 * Nonsense tag that tells the ad code nothing
 * goes here.
 * @type {String}
 */
export const blockRefreshTag = '<meta name="atlads" content="no-refresh">';

/**
 * GPT url
 */
export const gptUrl = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";

/**
 * Floor
 * @type {Number}
 */
export const floor = 0.25;


/**
 * Returns true if we've muted any bidders
 */
export const mutedAnyBidders = (() => {
  return !!Object.keys(debug).find((key) => key.startsWith("only-"))
})();