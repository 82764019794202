// @ts-check
import { isDoNotSell } from "../consent";
import { setGlobals } from "../options";
import { getGeoDataSync } from "../utils/geo";
import { log } from "../utils/log";
import { debug } from "../constants";

export default function handleDotNotSell() {
  // set key value
  setGlobals({ ccpa: isDoNotSell() });

  // set key value
  setGlobals({ ccpa: isDoNotSell() });

  log("__uspapi created for communicating CCPA state.");

  // Setup IAB API
  window.__uspapi = function (command, versionRequested, callback) {
    if (command !== "getUSPData") {
      console.error(
        "🙉 __uspapi Command wasn't getUSPData. No other commands are supported."
      );
      return;
    }

    // Let me launch this early
    if (new Date().getFullYear() < 2020 && !debug.donotsell) {
      callback(null);
      return;
    }

    const geodata = getGeoDataSync();

    // If the user is currently in California or they've previously opted
    // out, they're covered.
    const coveredUnderCCPA = geodata.region === "CA" || isDoNotSell();

    // See https://iabtechlab.com/wp-content/uploads/2019/11/U.S.-Privacy-String-v1.0-IAB-Tech-Lab.pdf
    // for possible parameters
    const version = 1;
    let noticeGiven = "-";
    let optedOut = "-";
    let limited_service_provider_agreement_member = "-";

    if (coveredUnderCCPA) {
      noticeGiven = "Y";
      optedOut = isDoNotSell() ? "Y" : "N";
      limited_service_provider_agreement_member = "Y";
    }

    callback(
      {
        version: version,
        uspString: [
          version,
          noticeGiven,
          optedOut,
          limited_service_provider_agreement_member,
        ].join(""),
      },
      true
    );
  };
}
