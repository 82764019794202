// @ts-check
import { urlParams } from "../constants";
import { getAbtValue, getSyncValue } from "../experiments/constants";
import { getOptions, setOptions, getGlobals, setGlobals } from "../options";
import { cookies } from "../utils/cookies";
import { getDataLayer } from "../utils/datalayer";
import { getCarbonSegments } from "../vendors/carbon";
import { justInTimeQueue } from "../queues";
import Bidstore from "../bidstore";

/**
 * Sets the zone options to reflect the param ?adtest=(1|2|3)
 * Or from ?gdt-domain=anydomain.
 */
export function gptDomainOverride() {
  const { zone } = getOptions();

  let zoneParts = (zone && zone.split("/")) || [];
  let domain = urlParams["gpt-domain"];
  if (domain) {
    zoneParts[2] = domain;
  }

  setOptions({
    zone: zoneParts.join("/"),
  });
}

/**
 * Set abt to a random value 1-100.
 * This will be used for testing programmatic
 * partners against each other.
 */
export function setRandomValue() {
  const { experiment } = getGlobals();

  setGlobals({
    abt: getAbtValue(),
    sync: getSyncValue(),

    // For AB tests powered by optimize or other third party services
    experiment: experiment || "null", // Yes null is a string
  });
}

export function setCDNABTestingValue() {
  setGlobals({
    cdn_test_group: cookies.get("atltestbucketv1") || undefined,
  });
}

export function pushDataToGTM() {
  // Push the value to GTM

  const abtValue = getAbtValue();
  const carbonSegments = getCarbonSegments();
  const avgBid = Bidstore.getAverageBid().toFixed(2);

  justInTimeQueue.push(() => {
    getDataLayer().push({
      event: "ads.ready",
      ads: {
        abTestValue: abtValue,
        carbonSegments: carbonSegments,
        avgBid: avgBid,
      },
    });
  });
}
