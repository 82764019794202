// @ts-check
//
// Straight port of cookies utils (+ES6)
// from https://github.com/theatlantic/Atlantic-CMS/blob/master/
//  apps/atlantic/common/static/common/js/cookies.js
//
//
export const cookies = {
  /**
   * @param {string} key
   * @param {string|Number} value
   * @param {number=} days
   */
  set: function (key, value, days) {
    if (days) {
      let date = new Date();
      // if day is undefined, date is NaN and expires="Invalid Date"
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      document.cookie = `${key}=${value}; expires=${date.toUTCString()}; path=/`;
    } else {
      document.cookie = `${key}=${value}; path=/`;
    }
  },
  /**
   * @param {string} key
   */
  get: function(key) {
    key = key + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(key) === 0) {
        return c.substring(key.length, c.length);
      }
    }
    return null;
  },
  /**
   * @param {string} key
   */
  delete: function(key) {
    this.set(key, "", -1);
  },
};
