// @ts-check
import { log } from "./utils/log";

//
// Queues
//

// Setup vendor queues
window.googletag = window.googletag || {};
window.googletag.cmd = window.googletag.cmd || [];
window.ad_queue = window.ad_queue || [];

// Setup internal queues
const internalQueues = {
  init: window.ad_queue,
  justInTimeQueue: [],
  lazyLoadQueue: [],
};

function Queue(key, externalCallback) {
  // Store shared stack.
  this.key = key;
  this.q = internalQueues[key]; // Empty on vendor queues

  // External queues need to be called
  // as a function to push to the stack on window.
  this.externalCallback = externalCallback || null;
}

/**
 * Resets queue storage and push API.
 * This prepares it for a new pageview. The
 * queue will be repopulated and the push method replaced
 * as part as of the ads lifecycle, but should always
 * start as the default.
 */
Queue.prototype.reset = function () {
  this.q.length = 0;

  // Remove the own property, so the method
  // from the prototype, native push, will be used.
  delete this.q.push;
};

//
// Add a thing to the queue.
//
Queue.prototype.push = function (thing) {
  if (this.externalCallback) {
    this.externalCallback(thing);
  } else {
    this.q.push(thing);
  }
};

//
// Replace push with an executing function.
//
Queue.prototype.setCallback = function (fn) {
  if (this.externalCallback) {
    this.externalCallback = fn;
  } else {
    this.q.push = fn;
  }
};

//
// Run all the tasks in the queue
// using the provided callback function.
//
Queue.prototype.execute = function (fn) {
  if (this.externalCallback) {
    console.error(`You can't execute the ${this.key} queue because it's a vendor API.`);
    return;
  }

  this.q.forEach(fn);
};

// Called when the ad stack is initiated
export const initQueue = new Queue("init");

/**
 * Called right before the ad call is made,
 * when all slots etc are available
 */
export const justInTimeQueue = new Queue("justInTimeQueue");

/**
 * Called during the lazy load cycle. Provides a hook
 * for optional services to run code during lazy loading.
 */
export const lazyLoadQueue = new Queue("lazyLoadQueue");

/**
 * For pushing tasks to GPT.
 */
export const gptQueue = new Queue("gpt", (thing) => {
  window.googletag.cmd.push(thing);
});

export const resetQueues = () => {
  log("Resetting queues");
  [initQueue, justInTimeQueue, lazyLoadQueue].forEach((Q) => Q.reset());
};
