// @ts-check
// <reference types="doubleclick-gpt" />
/** @typedef {import("./utils/types").GptAdElement} GptAdElement  */
/** @typedef {import("./utils/task").Task} Task  */

import { convertToArray } from "./utils/to-array";
import { performance } from "./utils/perf";
import { log, logError } from "./utils/log";
import { parseGptElement, resetIdCount } from "./gpt-element";
import { createSlot } from "./gpt-slots";
import { getSizesAtCurrentBreakpoint, getActiveBreakpoint } from "./utils/active-sizes";
import { getOptions } from "./options";
import { amazonBidderCodes, amazonPricesCodes } from "./vendors/amazon-constants";
import { resetGptAd } from "./reset";
import { getUnitCode } from "./vendors/prebid/helpers";
import { inViewObserver } from "./utils/observe-inview";

import { getGeoDataSync } from "./utils/geo";
import { getDeviceBreakpoint, getDeviceType } from "./utils/device";

/**
 * @type {GptAd[]}
 */
const gptAds = [];

/**
 * Class for gpt ad.
 *
 * @class      GptAd (name)
 */
export class GptAd {
  /**
   * Constructs the object.
   *
   * @param  {GptAdElement}  element - Custom DOM element
   */
  constructor(element) {
    log("Registerd an gpt-ad element", element);

    this.element = element;

    // Tracking whether the element is on the screen
    inViewObserver.observe(this.element);
    this.inView = false;

    // Parse the meaning of the markup into an object
    this.data = parseGptElement(element);

    // Helper method for tests and bookmarklets to find this
    // instance from the element.
    // @TODO: delete this when we have a better API
    this.element.inspect = function () {
      return GptAd.getById(this.id);
    };

    // State
    this.called = false;
    this.loaded = false;

    /** @type {null | number[]} */
    this.size = null;
    /** @type {null | googletag.Slot} */
    this.gptSlot = null;
    /** @type {null | boolean} */
    this.isResponsive = null;
    this.stalls = 0;
    /** @type {null | DOMHighResTimeStamp} */
    this.startTime = null;
    /** @type {null | DOMHighResTimeStamp} */
    this.loadtime = null;
    this.batch = 0;

    // Was the ad near the viewport the first time
    // the lazy-loader ran? If so, we measure its TTFA
    // from the controller rather than when the lazy-loader
    // picked it up.
    this.inLazyRangeAtPageLoad = false;

    // % of browser memory used at time of ad load
    this.memoryAtLoad = null;
    // Millisecond delay on a setTimeout that should fire after 0ms.
    // Quantifies how overloaded the thread is when the ad is about to load.
    this.delayAtLoad = null;

    // GPT Info
    this.advertiserId = null;
    this.lineItemId = null;
    this.creativeId = null;
    this.campaignId = null;
    this.hadViewableImpression = null;

    // Tasks that must be completed before
    // calling the ad. Fill this with Task classes.
    /** @type {Task[]} */
    this.tasks = [];

    /** @type {Object} */
    this._recordedTargeting = {};
  }

  /**
   * Gets the lazy load value for this ad. Default is 0
   * @return {number} If set, how many screens ahead to lazy load
   */
  getLazySetting() {
    let { lazy_load } = getOptions();

    if (this.element.hasAttribute("lazy-load")) {
      const lazyLoadSetting = this.element.getAttribute("lazy-load");
      return lazyLoadSetting !== null ? parseFloat(lazyLoadSetting) : 0;
    }
    return lazy_load || 0;
  }

  /**
   * Gets the ad unit path and automatically appents format
   */
  getAdUnitPath() {
    const { zone } = getOptions();
    const format = this.data.format;

    return zone + "/" + format;
  }

  /**
   * Creates GPT slot.
   * WARNING: To avoid race conditions, this should
   * be run after all page-level targeting is set.
   *
   * @see http://bit.ly/2gr1gfy
   */
  createGptSlot() {
    const adunitpath = this.getAdUnitPath();

    if (!this.gptSlot) {
      this.gptSlot = createSlot(this, adunitpath);
    }
  }

  /**
   * Wrapper tag for getting this.gptSlot so we can ensure it's been created first
   * @returns googletag.Slot
   */
  getGptSlot() {
    if (this.gptSlot == null) {
      logError("Attempted to get gptSlot before it was created");
      this.createGptSlot();
    }
    /** @type googletag.Slot */
    // @ts-ignore
    const slot = this.gptSlot;
    return slot;
  }

  isDeferred() {
    return this.element.hasAttribute("defer");
  }

  setCalled() {
    this.called = true;
    this.element.classList.add("ad-called");
    this.startTime = performance.now();
  }

  getGoogleQueryId() {
    return this.element.getAttribute("data-google-query-id");
  }

  /**
   * Determines if there are unresolved promises
   * in the task queue?
   *
   * @return    {Boolean}  Are there?
   */
  isTaskQueueComplete() {
    return this.tasks.filter((task) => !task.isComplete).length === 0;
  }

  /**
   * Return a list of sizes that can run at
   * the current breakpoint.
   *
   * @return     {Array<Array<number>>}  The active sizes.
   */
  getActiveSizes() {
    return getSizesAtCurrentBreakpoint(this);
  }

  /**
   * Return width of the active breakpoint
   */
  getActiveBreakpoint() {
    return getActiveBreakpoint(this);
  }

  /**
   * A descriptive label for revops analytics
   * in the format
   *
   *   device_format_country
   *
   * e.g.
   *
   *   desktop_injector_us
   *   mobile_rrail_non-us
   *
   * @return     {String}  The slotName.
   */
  getSlotName() {
    // change after the first time its accessed.
    if (this._slotName) {
      return this._slotName;
    }

    let { country } = getGeoDataSync();
    if (!country) {
      // We don't know this yet
      country = "unknown";
    } else if (country === "USA") {
      country = "us";
    } else {
      country = "non-us";
    }

    const device = getDeviceBreakpoint();
    const format = this.data.format;

    this._slotName = `${device}_${format}_${country}`;
    return this._slotName;
  }

  get id() {
    return this.element.id;
  }

  /**
   * Re-creates the functionality of the now-defunct method
   * this.getGptSlot().getTargetingMap()
   * @returns {Object}
   */
  getTargetingMap() {
    const map = {};
    const keys = this.getGptSlot().getTargetingKeys();
    keys.forEach((key) => (map[key] = this.getGptSlot().getTargeting(key)));
    return map;
  }

  /**
   * Gets the highest bid as a number, which will be our floor price for server-side bids
   * @returns {(number|undefined)}
   */
  getMaxBid() {
    let bids = this.getBids();
    const bidders = Object.keys(bids);
    const targetingMap = this.getTargetingMap();

    // Determine the highest Prebid bid (excluding 1x3 bids)
    let highestPrebidBid;
    if (bidders.length > 0) {
      const prebidBids = [];
      bidders.forEach((bidder) => {
        const bidderName = bidder.split("_")[1];
        const bidderSize = `hb_size_${bidderName}`;
        const bidderFormat = (
          targetingMap[`hb_format_${bidderName}`] || ["unspecified"]
        ).join("");
        if (
          bidder.includes("prebid_") &&
          targetingMap.hasOwnProperty(bidderSize) &&
          targetingMap[bidderSize][0] !== "1x3" &&
          bidderFormat !== "video"
        ) {
          prebidBids.push(parseFloat(bids[bidder]));
        }
      });

      if (prebidBids.length > 0) {
        highestPrebidBid = Math.max(...prebidBids);
      }
    }

    // find the highest amazon bid
    let highestAmznBid;
    const amazonBids = this.getAmazonBids();
    for (let key in amazonBids) {
      const bid = parseFloat(amazonBids[key]);
      if (!highestAmznBid || bid > highestAmznBid) {
        highestAmznBid = bid;
      }
    }

    // Prebid is the winner
    if (highestPrebidBid && (!highestAmznBid || highestPrebidBid > highestAmznBid)) {
      return highestPrebidBid;
    }
    // Amazon is the winner
    else if (highestAmznBid) {
      return highestAmznBid;
    }

    // return nothing if there are no bids
    return undefined;
  }

  getSecondHighestBid() {
    let allBids = [];

    // Get Prebid bids
    const adUnitCode = getUnitCode(this);
    const prebidBids = Object.values(
      window.pbjs.getBidResponsesForAdUnitCode(adUnitCode)
    )[0];
    prebidBids.forEach((bid) => {
      // pbCg incorporates our custom price granularity (pricingConfig):
      // https://github.com/theatlantic/ads.js/blob/master/src/lib/vendors/prebid/settings.js#L47
      allBids.push(parseFloat(bid.pbCg));
    });

    // Get Amazon bids
    const amazonBids = Object.values(this.getAmazonBids());
    amazonBids.forEach((bid) => {
      allBids.push(parseFloat(bid));
    });

    // Return undefined if there aren't at least two bids
    if (allBids.length < 2) {
      return undefined;
    }

    // Sort the bids from highest to lowest
    allBids = allBids.sort((a, b) => b - a);
    // Stringify each element in the array
    allBids = allBids.map((bid) => bid.toFixed(2));
    // Return the second-highest bid
    return allBids[1];
  }

  /**
   * What bids did this receive?
   *
   * @return     {Object.<string, string>| {}}  The bids.
   */
  getAmazonBids() {
    const targeting = this.getTargetingMap();
    const bids = {};

    if ((targeting.amznbid || []).length) {
      /**
       * @param {number} bid
       * @param {string | number} i
       */
      targeting.amznbid.forEach((bid, i) => {
        // Amazon passes numbers sometimes. Ignore them.
        // 0 = No bid request made yet
        // 1 = Add call was made before the bid came back
        // 2 = Bid response returned, no bid

        if (bid == 1 || bid == 2 || bid == 0) {
          return;
        }

        // Lookup a meaningful name for the TAM partner
        const amazonPartnerId = targeting.amznp[i];

        const amazonPartner =
          amazonBidderCodes[amazonPartnerId] || `tam_unmapped_${amazonPartnerId}`;

        // Lookup the actual bid value from the code they send
        const realBidValue = amazonPricesCodes[bid];

        // If there's no number, run!
        if (realBidValue === undefined) {
          console.warn("A9 returned a bid that does not map to a real value");
          return;
        }

        bids[amazonPartner] = realBidValue;
      });
    }
    return bids;
  }

  /**
   * What bids did this receive?
   *
   * @return     {Object}  The bids.
   */
  getBids() {
    const targeting = this.getTargetingMap();
    let bids = {};

    // Prebid
    Object.keys(this.getTargetingMap())
      // get all the key values that start with "hb_pb_". These are the prebid bids
      .filter((key) => {
        return key.indexOf("hb_pb_") === 0;
      })
      // rename them as prebid_ instead of hb_pb_
      .forEach((key) => {
        // Filter out zeros, they should not exist
        let num = parseFloat(targeting[key][0]);
        if (num > 0 && !isNaN(num)) {
          bids[key.replace("hb_pb_", "prebid_")] = targeting[key][0];
        }
      });

    const amzn = this.getAmazonBids();
    bids = Object.assign({}, bids, amzn);

    return bids;
  }

  /**
   * Who bid on this?
   */
  getBidders() {
    return Object.keys(this.getBids());
  }

  /**
   * Remember the current targeting. This should be used
   * just before the ad is called so we can determine precisely
   * how it was called.
   */
  recordTargeting() {
    this._recordedTargeting = Object.assign({}, this.getTargetingMap());
  }

  /**
   * Get targeting from the last ad call.
   */
  getRecordedTargeting() {
    return this._recordedTargeting || {};
  }

  /**
   * Forgot this ad exists
   */
  unregister() {
    gptAds.splice(gptAds.indexOf(this), 1);
    this.element.removeAttribute("id");
  }

  /**
   * Clear the ad so it could be called again.
   */
  reset() {
    resetGptAd(this);
  }

  /**
   * Get all ads
   *
   * @return     {Array}  - Array of all ad instances
   */
  static all() {
    return gptAds;
  }

  /**
   * Forget all the registered ads.
   * Mostly used for unit tests.
   */
  static clear() {
    resetIdCount();

    gptAds.map((ad) => ad.reset());
    gptAds.length = 0;
  }

  /**
   * Find instance by the element's id
   *
   * @param      {String}  id - Element ID
   * @return     {GptAd | undefined}   GptAd instance
   */
  static getById(id) {
    return gptAds.filter((ad) => ad.element.id === id)[0];
  }

  setTabIndex(num = -1) {
    if (!this.element) {
      return;
    }

    const currentTabindex = this.element.getAttribute("tabindex");
    if (
      currentTabindex == null ||
      typeof currentTabindex == undefined ||
      // @ts-ignore
      isNaN(currentTabindex)
    ) {
      this.element.setAttribute("tabindex", num.toString());
    }
  }
}



/**
 * Find all new gpt elements on the page and
 * create instances of GptAd to represent them.
 */
export function registerElements() {
  // Find any gpt-ad elements we don't already know about
  const registeredIds = GptAd.all().map((ad) => ad.id);
  const elements = convertToArray(document.getElementsByTagName("gpt-ad")).filter(
    (el) => {
      return !el.id || registeredIds.indexOf(el.id) === -1;
    }
  );

  const instances = elements.map((el) => new GptAd(el));
  if (instances.length) {
    log(`Found ${instances.length} <gpt-ad> elements`);
  }
  instances.forEach((inst) => {
    gptAds.push(inst);
  });
}
