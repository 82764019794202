// @ts-check
/** @typedef {import("./utils/types").Options} Options  */
/** @typedef {import("./utils/types").Globals} Globals  */

import { logError } from "./utils/log";

/** @type Options */
const DEFAULT_OPTIONS = {
  globals: {},
};

/**
 * Data store for controller-level options
 * @type Options
 */
let data = { ...DEFAULT_OPTIONS };

export function clearOptions() {
  data = { ...DEFAULT_OPTIONS };
}

/**
 * Set a subset of options
 * @param {object} extra
 */
export function setOptions(extra) {
  Object.assign(data, extra);
}

/**
 * Creates an initial options object
 * @param {any} options Should be a complete options object
 * @return {Options}
 */
export function createOptions(options) {
  let newOptions = { ...DEFAULT_OPTIONS };
  if (!options) {
    logError("Hummingbird: Options must be provided.");
    Object.assign(newOptions, DEFAULT_OPTIONS);
    return newOptions;
  }
  if (typeof options !== "object") {
    logError("Hummingbird: Options must be an object.");
    Object.assign(newOptions, DEFAULT_OPTIONS);
    return newOptions;
  }

  newOptions = { ...newOptions, ...options };

  // set up globals
  /** @type {any} */
  let globals = { ...DEFAULT_OPTIONS.globals };
  if (options.hasOwnProperty("globals") && typeof options.globals === "object") {
    globals = { ...globals, ...options.globals };
  }
  newOptions = { ...newOptions, globals };

  // print error if no zone
  if (!options.hasOwnProperty("zone") || typeof options.zone !== "string") {
    logError("Hummingbird: Options must have a zone string.");
  }
  return newOptions;
}

/**
 * @returns {Options}
 */
export function getOptions() {
  return data;
}

/**
 * @param  {string[]} [keys]
 * @returns {Globals}
 */
export function getGlobals(keys) {
  let result = {};
  if (keys && keys.length) {
    keys.forEach((key) => {
      result[key] = data.globals[key];
    });
    return result;
  }
  // if no keys are provided, just return all of it
  return data.globals;
}

/**
 * @param {Globals} extra
 */
export function setGlobals(extra) {
  Object.assign(data.globals, extra);
}
