// @ts-check
/** @typedef {import("./utils/types").Atlantic} Atlantic  */
/** @typedef {import("./utils/types").AdsAPI} AdsAPI  */
/** @typedef {import("./utils/types").Globals} Globals  */
/**
 * Sets up the window.__ads API
 */

import { createOptions, getGlobals } from "./options";
import { dispatch } from "./utils/event";
import { GptAd } from "./models";
import { incrementPageCount } from "./utils/page-count";
import { initQueue } from "./queues";
import { setGlobals } from "./options";
import Controller from "./controller";
import { trackPermutiveEvent } from "./vendors/permutive";

/** @type Controller */
let controllerInstance;

// stores the calls to setGlobalTargeting that existed before hummingbird loaded
let setGlobalTargetingQueue;

export function getControllerInstance() {
  return controllerInstance;
}

const pageview = {
  /**
   * @param {unknown} options
   */
  push: (options) => {
    let opt = createOptions(options);
    // Add any global targeting that was set up before this file loaded
    if (setGlobalTargetingQueue.length) {
      const globals = setGlobalTargetingQueue.reduce(
        (prev, curr) => ({ ...prev, ...curr }),
        {}
      );
      opt = { ...opt, globals: { ...opt.globals, ...globals } };
      setGlobalTargetingQueue = [];
    }

    if (!controllerInstance) {
      // The first time we call this we need to create the controller
      controllerInstance = new Controller(opt);
    } else {
      try {
        // After that, fire new pageviews
        controllerInstance.reset(opt);
      } catch (e) {
        // Never allow ad bugs to crash the site
        console.error("Hummingbird reset failure.", e);
      }
    }
    incrementPageCount();
  },
};

/**
 * Pauses ad loading
 */
const pause = {
  push: () => (controllerInstance ? controllerInstance.pause() : undefined),
};

/**
 * Adds key/values to options.globals.
 * options.globals is page targeting.
 */
const setGlobalTargeting = {
  // Once options are loaded up, add
  // page-level key/values from any outside code
  /**
   * @param {Globals} extraGlobalTargeting
   */
  push: (extraGlobalTargeting) => {
    initQueue.push(() => {
      setGlobals(extraGlobalTargeting);
    });
  },
};

/**
 * Inspect an ad from a DOM element or ID.
 *
 * @param {HTMLElement|String} param
 * @return {GptAd | undefined}
 */
const getAd = (param) => {
  const id =
    (typeof param !== "string" && param.id) || (typeof param === "string" && param) || "";
  return GptAd.getById(id);
};

/**
 * Sets up the window.__ads API
 */
export function initAPI() {
  // Use the existing, scaffolded window.__ads (for its queues),
  // or set empty
  window.__ads = window.__ads || makeAPI();

  // Pull the existing data for existing queues
  const pageQueue = window.__ads.pageview instanceof Array ? window.__ads.pageview : [];
  setGlobalTargetingQueue =
    window.__ads.setGlobalTargeting instanceof Array
      ? window.__ads.setGlobalTargeting
      : [];
  // pause is also a queue but we don't run it because there's no reason
  // to pause if it ads never started in the first place

  // Set the new API if it was not already set up
  if (!window.__ads.loaded) {
    window.__ads = makeAPI();
  }

  // Run whatever was in the pageQueue from before. This will also pull the setGlobalTargetingQueue
  pageQueue.forEach((options) => window.__ads.pageview.push(options));

  dispatch("ads-api-ready", window);
}

/**
 * @returns {AdsAPI}
 */
function makeAPI() {
  return {
    pageview,
    setGlobalTargeting,
    pause,
    get: getAd,
    /**
     * Return page data that was passed into ads.js via options.globals. This is a utility function
     * that allows things like house ads to grab the article id, etc. If no keys are provided, all
     * globals are returned.
     * @param  {...string} keys
     */
    getGlobals: (...keys) => getGlobals(keys),
    adsBlocked: null,
    loaded: true,
    trackPermutiveEvent,
  };
}
