// @ts-check
import { urlParams } from "../constants";
import { setGlobals } from "../options";

/**
 * @param {string | undefined} referrer
 * @returns {string} - the referrer's category
 * Given a referrer, return its category.
 */
function getReferrerCategory(referrer) {
  const domains = {
    top_aggregators: ["flipboard.com", "www.smartnews.com", "apple.news"],
    search: ["com.google.android.googlequicksearchbox"],
    social: [
      "l.facebook.com",
      "lm.facebook.com",
      "t.co",
      "www.reddit.com",
      "l.instagram.com",
      "ml.instagram.com",
    ],
  };

  if (!referrer) {
    return "direct";
  }

  for (const category in domains) {
    for (const domain of domains[category]) {
      if (domain === referrer) {
        return category;
      }
    }
  }

  // Match Google for all domains
  if (referrer.match(/(?:^|\.)google\./)) {
    if (referrer.includes("news.google")) {
      return "top_aggregators";
    }
    return "search";
  }

  if (urlParams.utm_source === "newsletter") {
    return "newsletter";
  }
  if (urlParams.utm_source && urlParams.utm_source.includes("pocket")) {
    return "top_aggregators";
  }
  return "other";
}

export default function referrers() {
  // If there is a referrer but it is not The Atlantic,
  // and it has not yet been set in sessionStorage,
  // set it in sessionStorage.
  if (
    document.referrer &&
    document.referrer.split("/")[2] !== window.location.hostname &&
    !sessionStorage.referrer
  ) {
    sessionStorage.referrer = document.referrer.split("/")[2];
  }

  setGlobals({
    referrer: sessionStorage.referrer,
    referrer_category: getReferrerCategory(
      sessionStorage.referrer || urlParams.utm_source
    ),
  });
}
