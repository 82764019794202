// @ts-check
import { allowAllTracking, allowFirstPartyTracking } from "../../consent";
import { debug, floor } from "../../constants";
import { getDeviceType } from "../../utils/device";

// How many extra screens ahead should we fetch bids?
export const lazyBidOffset = 0.5;
// For ads in batches 2+. Batch 1 is the first ads to load on the page.
export const lazyBidOffsetLaterBatch = 1;

// Timeout set for initial ad call
export const auctionTimeout = 1500;

// Timeout for lazy-loaded ads
export const auctionTimeoutLaterBatch = (() => {
  if (getDeviceType() === "desktop") {
    return 2250;
  }
  return 3000;
})();

/** @typedef PrebidConfig
 * @property {boolean} debug
 * @property {number} bidderTimeout
 * @property {boolean} useBidCache
 * @property {string} pageUrl
 * @property {boolean} enableSendAllBids
 * @property {object} priceGranularity
 * @property {object[]} priceGranularity.buckets
 * @property {number} priceGranularity.buckets.precision
 * @property {number} priceGranularity.buckets.min
 * @property {number} priceGranularity.buckets.max
 * @property {number} priceGranularity.buckets.increment
 * @property {object|undefined} userSync
 * @property {object} currency
 * @property {object} floors
 * @property {boolean} enableTIDs
 * @property {object} realTimeData
 * @property {object[]} multibid
 */

/**
 * Convert minutes to milliseconds
 *
 * @param      {number}  num - Number of minutes
 * @return     {number}  Number of miliseconds for settimeout etc.
 */
function minutes(num) {
  return num * 60 * 1000;
}

export const allowedSizesByPriority = [
  "300x250",
  "320x50",
  "728x90",
  "300x600",
  "970x250",
  "1x3", // responsive, used by teads
  "620x366", // used by concert - desktop
  "325x508", // used by concert - mobile
  "325x204", // used by concert - mobile
];

// Describes the prices we have line items set for
// $0 - $2  //  2c  //  100 lines
// $2 - $10 //  5c  //  160 lines
// $10 - $25  // 25c  // 60 lines
// $25 - $50  // 50c  // 50 lines
// $50 - $100 // $1  // 50 lines
export const pricingConfig = {
  buckets: [
    {
      precision: 2, // default is 2 if omitted - means 2.1234
      min: 0, // rounded to 2 decimal places = 2.12
      max: 2,
      increment: 0.02,
    },
    {
      precision: 2,
      min: 2,
      max: 10,
      increment: 0.05,
    },
    {
      precision: 2,
      min: 10,
      max: 25,
      increment: 0.25,
    },
    {
      precision: 2,
      min: 25,
      max: 50,
      increment: 0.5,
    },
    {
      precision: 2,
      min: 50,
      max: 100,
      increment: 1,
    },
  ],
};

/**
 * Create config for Prebid.js
 *
 * @returns {Object} - Prebid config object
 */
export function basePrebidConfig() {
  /** @type PrebidConfig */
  const config = {
    debug: debug.prebid,
    bidderTimeout: auctionTimeout,
    useBidCache: true,
    pageUrl: window.location.href,
    enableSendAllBids: allowAllTracking(),
    // enableSendAllBids Does weird things to targeting keys.
    // @See http://prebid.org/adops/send-all-bids-adops.html
    //      http://prebid.org/dev-docs/bidders.html#audienceNetwork
    priceGranularity: pricingConfig,
    enableTIDs: true,
    userSync: {
      filterSettings: {
        iframe: {
          bidders: [
            "pubmatic",
            "rubicon",
            "appnexus", // aka Xandr (see: https://docs.prebid.org/dev-docs/bidders.html)
            "triplelift",
            "ix",
            "concert",
            "teads",
            "criteo",
          ],
          filter: "include",
        },
        image: {
          bidders: "*",
          filter: "include",
        },
      },
      userIds: [
        {
          name: "criteo",
        },
      ],
    },
    currency: {
      // Set the currency to avoid unexpected behavior in price floors
      // @See http://docs.prebid.org/dev-docs/modules/floors.html#currency
      adServerCurrency: "USD",
      // Required by Criteo for price floors, even though it is a fallback
      // used only if Prebid's conversion rates data fails to load.
      defaultRates: {
        USD: {
          CAD: 1.3189669771,
          HKD: 7.750042337,
          ISK: 138.8653683319,
          PHP: 48.5461473328,
          DKK: 6.3018628281,
          HUF: 309.6359017782,
          CZK: 23.0592718036,
          GBP: 0.773319221,
          RON: 4.1287891617,
          SEK: 8.7895850974,
          IDR: 14710.49957663,
          INR: 73.5364098222,
          BRL: 5.6038950042,
          RUB: 77.9202370872,
          HRK: 6.4204911092,
          JPY: 105.6646909399,
          THB: 31.299745978,
          CHF: 0.9080440305,
          EUR: 0.8467400508,
          MYR: 4.150465707,
          BGN: 1.6560541914,
          TRY: 7.900169348,
          CNY: 6.6823031329,
          NOK: 9.2885690093,
          NZD: 1.5246401355,
          ZAR: 16.5321761219,
          USD: 1,
          MXN: 21.1951735817,
          SGD: 1.3578323455,
          AUD: 1.4226079594,
          ILS: 3.3794242168,
          KRW: 1140.0084674005,
          PLN: 3.8788314987,
        },
      },
    },
    floors: {
      data: {
        schema: {
          fields: ["mediaType", "size"],
        },
        values: {
          "*|*": floor,
        },
        default: floor,
      },
    },
    realTimeData: {
      dataProviders: [
        {
          name: "permutive",
        },
      ],
    },
    multibid: [
      {
        bidder: "pubmatic",
        maxBids: 2,
        targetBiddercodePrefix: "pubmatic",
      },
    ],
  };

  if (!allowAllTracking()) {
    config.userSync = { enabled: false };
  }

  // SharedID
  if (allowFirstPartyTracking()) {
    config.userSync.userIds.push({
      name: "sharedId",
      storage: {
        name: "_sharedID",
        type: "cookie",
        expires: 30,
      },
    });
  }

  return config;
}

export function addGDPRToConfig(config) {
  return Object.assign({}, config, {
    consentManagement: {
      gdpr: {
        cmpApi: "iab", // activates the enforcement module
        defaultGdprScope: true,
        rules: [
          {
            purpose: 'storage',
            enforcePurpose: true,
            enforceVendor: true,
            vendorExceptions: ["permutive"]
          },
          {
            purpose: 'basicAds',
            enforcePurpose: true,
            enforceVendor: true,
            vendorExceptions: []
          }
        ]
      },
      timeout: 500,
      allowAuctionWithoutConsent: false,
    },
  });
}

// https://docs.prebid.org/dev-docs/modules/consentManagementUsp.html
export function addUSPrivacyToConfig(config) {
  return Object.assign({}, config, {
    usp: {
      cmpApi: "iab",
      timeout: 100,
    },
  });
}

// Manually mark the user having not consented with U.S. Privacy API
// https://docs.prebid.org/dev-docs/modules/consentManagementUsp.html
export function addManualUSPrivacyToConfig(config) {
  return Object.assign({}, config, {
    usp: {
      cmpApi: "static",
      consentData: {
        getUSPData: {
          uspString: "1YYY",
        },
      },
    },
  });
}

/**
 * The coppa flag turns off personalization
 *
 * @param {object} config The prebid config that will be set with pbjs.setConfig
 * @returns {object} The new prebid config
 * @see https://docs.prebid.org/dev-docs/publisher-api-reference/setConfig.html#setConfig-coppa
 */
export function addCoppaToConfig(config) {
  return Object.assign({}, config, {
    coppa: true,
    deviceAccess: false,
  });
}

/**
 * Add PAAPI, or Protected Access API (aka Fledge)
 * to the Prebid config
 *
 * @param {object} config The prebid config that will be set with pbjs.setConfig
 * @returns {object} The new prebid config
 * @see https://publisherdocs.criteotilt.com/prebid/#recommended-protected-audience-api-for-prebid-8-42-
 */
export function addPAAPIToConfig(config) {
  return Object.assign({}, config, {
    paapi: {
      enabled: true,
      gpt: {
        autoconfig: true,
      },
      defaultForSlots: 1, // enable for all ad units
      bidders: ["criteo" /* other bidders */],
    },
  });
}
