// @ts-check
/** @typedef {import("../../utils/types").PrebidUnit} PrebidUnit  */

import { getDeviceBreakpoint } from "../../utils/device";
import { getOptions } from "../../options";
import { getHasSize } from "./helpers";
import { experiments } from "../../experiments/constants";
import { log } from "../../utils/log";
import { mutedAnyBidders, debug } from "../../constants";

/**
 * Note: if any of these bidders do not support ccpa, we have to
 * disable them if !allowAllTracking. They currently all support it but we
 * can find out if they do in the future here:
 * https://docs.prebid.org/dev-docs/modules/consentManagementUsp.html
 */

// These functions should receive unit descriptions for Prebid
// and populate their bids list.

// Maps Zone IDs to Sizes
// https://docs.google.com/spreadsheets/d/1jYFXEocR4RE1SM3TrGY7gxEakl8cmlGFveYwiMSfw04/edit#gid=0
const criteoMappings = {
  mobile_injector_us: 1230211,
  "mobile_injector_non-us": 1407710,
  mobile_rrail_us: 1407713,
  "mobile_rrail_non-us": 1407711,
  mobile_adhesion_us: 1230205,
  "mobile_adhesion_non-us": 1407712,
  tablet_injector_us: 1230330,
  "tablet_injector_non-us": 1407707,
  tablet_rrail_us: 1230328,
  "tablet_rrail_non-us": 1407708,
  tablet_adhesion_us: 1230326,
  "tablet_adhesion_non-us": 1407709,
  desktop_injector_us: 1230203,
  "desktop_injector_non-us": 1407706,
  desktop_rrail_us: 1444602,
  "desktop_rrail_non-us": 1407705,
  desktop_adhesion_us: 1230200,
  "desktop_adhesion_non-us": 1407704,
  desktop_injector_unknown: 1444603,
  desktop_rrail_unknown: 1444601,
  desktop_adhesion_unknown: 1444605,
  mobile_injector_unknown: 1444609,
  mobile_rrail_unknown: 1444608,
  mobile_adhesion_unknown: 1444607,
  tablet_injector_unknown: 1444604,
  tablet_rrail_unknown: 1444600,
  tablet_adhesion_unknown: 1444606,
};

/**
 * Add the Criteo bid information to the unit
 *
 * @param {PrebidUnit} unit The unit to add bids to
 */
export function addCriteoBid(unit) {
  if (mutedAnyBidders && !debug["only-criteo"]) {
    log("Muting Criteo");
    return;
  }
  unit.bids.push({
    bidder: "criteo",
    params: {
      pubid: "73RDXP",
      zoneId: criteoMappings[unit._slotName],
      networkId: 7221,
    },
  });
}

/**
 * Add the TripleLift bid information to the unit
 *
 * @param {PrebidUnit} unit The unit to add bids to
 */
export function addTripleLiftBid(unit) {
  if (mutedAnyBidders && !debug["only-triplelift"]) {
    log("Muting TripleLift");
    return;
  }
  const inventoryCodeMap = {
    "300x250": "theatlantic_mobile_rectangle_PB",
    "970x250": "theatlantic_leaderboard_PB",
    "1x3": "theatlantic_infeed_native_PB",
  };

  // Map sizes to inventory codes and request bids for them
  let codes = [];
  unit.mediaTypes.banner.sizes
    .map((size) => {
      // @ts-ignore-next-line
      return inventoryCodeMap[size.join("x")];
    })
    .forEach((code) => {
      // Dedupe the list, filter out undefined
      if (code && codes.indexOf(code) === -1) {
        codes.push(code);
      }
    });

  codes.forEach((code) => {
    unit.bids.push({
      bidder: "triplelift",
      params: {
        inventoryCode: code,
      },
    });
  });
}

/**
 * Add the AppNexus (aka Xandr) bid information
 *
 * @param      {PrebidUnit}  unit    The prebid unit
 */
export function addAppNexusBid(unit) {
  if (mutedAnyBidders && !debug["only-appnexus"]) {
    log("Muting AppNexus");
    return;
  }
  const deviceType = getDeviceBreakpoint();

  // AN bases all its reporting off of
  // placementId's. We have a default for desktop and
  // mobile. The rest map off slotName.
  let placementId = 13395560;
  if (deviceType !== "mobile") {
    placementId = 13395544;
  }

  // We have specific placementIDs for most valid slotNames
  // that should be able to receive a bid.
  const slotNameMappings = {
    "desktop_adhesion_non-us": 16889974,
    "desktop_injector_non-us": 16889968,
    "desktop_rrail_non-us": 16889972,
    "desktop_unknown_non-us": 17396299,
    "mobile_adhesion_non-us": 16889956,
    "mobile_injector_non-us": 16889950,
    "mobile_rrail_non-us": 16889953,
    "mobile_unknown_non-us": 17396301,
    "tablet_adhesion_non-us": 16889962,
    "tablet_injector_non-us": 16889958,
    "tablet_rrail_non-us": 16889960,
    "tablet_unknown_non-us": 17396300,
    desktop_adhesion_unknown: 17396287,
    desktop_adhesion_us: 16889973,
    desktop_injector_unknown: 17396288,
    desktop_injector_us: 16889967,
    desktop_rrail_unknown: 17396289,
    desktop_rrail_us: 16889970,
    desktop_unknown_unknown: 17396302,
    desktop_unknown_us: 17396296,
    mobile_adhesion_unknown: 17396290,
    mobile_adhesion_us: 16889954,
    mobile_injector_unknown: 17396291,
    mobile_injector_us: 16889949,
    mobile_rrail_unknown: 17396292,
    mobile_rrail_us: 16889951,
    mobile_unknown_unknown: 17396304,
    mobile_unknown_us: 17396298,
    tablet_adhesion_unknown: 17396293,
    tablet_adhesion_us: 16889961,
    tablet_injector_unknown: 17396294,
    tablet_injector_us: 16889957,
    tablet_rrail_unknown: 17396295,
    tablet_rrail_us: 16889959,
    tablet_unknown_unknown: 17396303,
    tablet_unknown_us: 17396297,
  };

  if (slotNameMappings[unit._slotName]) {
    placementId = slotNameMappings[unit._slotName];
  }

  unit.bids.push({
    bidder: "appnexus", // the call for Xandr is still "appnexus", see: https://docs.prebid.org/dev-docs/bidders.html
    params: {
      placementId: placementId,
      member: 7498,
    },
  });

  // Make a request for outstream (outstream_high_impact_1x3)
  if (debug["appnexus-outstream"] && getHasSize(unit, "1x3")) {
    unit.bids.push({
      bidder: "appnexus",
      params: {
        placementId: 29744110,
      },
    });
  }
}

const indexExchangeMappings = {
  mobile_injector_us: 417250,
  "mobile_injector_non-us": 417251,
  mobile_rrail_us: 417252,
  "mobile_rrail_non-us": 417253,
  mobile_adhesion_us: 417254,
  "mobile_adhesion_non-us": 417255,
  tablet_injector_us: 417256,
  "tablet_injector_non-us": 417257,
  tablet_rrail_us: 417258,
  "tablet_rrail_non-us": 417259,
  tablet_adhesion_us: 417260,
  "tablet_adhesion_non-us": 417261,
  desktop_injector_us: 417262,
  "desktop_injector_non-us": 417263,
  desktop_rrail_us: 417264,
  "desktop_rrail_non-us": 417265,
  desktop_adhesion_us: 417266,
  "desktop_adhesion_non-us": 417267,
  desktop_injector_unknown: 417268,
  desktop_rrail_unknown: 417269,
  desktop_adhesion_unknown: 417270,
  mobile_injector_unknown: 417271,
  mobile_rrail_unknown: 417272,
  mobile_adhesion_unknown: 417273,
  tablet_injector_unknown: 417274,
  tablet_rrail_unknown: 417275,
  tablet_adhesion_unknown: 417276,
};

/**
 * Add the IndexExchange bid information
 *
 * @param      {PrebidUnit}  unit    The prebid unit
 */
export function addIndexExchangeBid(unit) {
  if (mutedAnyBidders && !debug["only-ix"]) {
    log("Muting IndexExchange");
    return;
  }
  const reportingId = indexExchangeMappings[unit._slotName];

  let sizeIdMap = {
    "300x250": "274648",
    "320x50": "274649",
  };
  // desktop and tablet
  if (getDeviceBreakpoint() !== "mobile") {
    // @ts-ignore
    sizeIdMap = {
      "300x250": "274644",
      "300x600": "274645",
      "728x90": "274646",
      "970x250": "274647",
    };
  }

  // Filter down to the sizes we have placement ids for

  let sizes = unit.mediaTypes.banner.sizes.filter((size) => {
    // @ts-ignore
    return sizeIdMap[size && size.join("x")] !== undefined;
  });

  // Don't bid if there's no valid sizes.
  if (sizes.length === 0) {
    return;
  }

  // Each size is a bid
  sizes.forEach((size) => {
    unit.bids.push({
      bidder: "ix",
      params: {
        siteId: reportingId,
        size: size,
      },
    });
  });
}

const rubiconZones = {
  "desktop_adhesion_non-us": 1417850,
  desktop_adhesion_unknown: 1417858,
  desktop_adhesion_us: 1417848,
  "desktop_injector_non-us": 1417842,
  desktop_injector_unknown: 1417852,
  desktop_injector_us: 1417840,
  "desktop_rrail_non-us": 1417846,
  desktop_rrail_unknown: 1417854,
  desktop_rrail_us: 1417844,
  "mobile_adhesion_non-us": 1417826,
  mobile_adhesion_unknown: 1417866,
  mobile_adhesion_us: 1417824,
  "mobile_injector_non-us": 1417816,
  mobile_injector_unknown: 1417860,
  mobile_injector_us: 1417814,
  "mobile_rrail_non-us": 1417822,
  mobile_rrail_unknown: 1417862,
  mobile_rrail_us: 1417818,
  "tablet_adhesion_non-us": 1417838,
  tablet_adhesion_unknown: 1417874,
  tablet_adhesion_us: 1417836,
  "tablet_injector_non-us": 1417830,
  tablet_injector_unknown: 1417870,
  tablet_injector_us: 1417828,
  "tablet_rrail_non-us": 1417834,
  tablet_rrail_unknown: 1417872,
  tablet_rrail_us: 1417832,
};

/**
 * Add the Rubicon bid information
 *
 * @param      {PrebidUnit}  unit    The prebid unit
 */
export function addRubiconBid(unit) {
  if (mutedAnyBidders && !debug["only-rubicon"]) {
    log("Muting Rubicon");
    return;
  }
  const zoneId = rubiconZones[unit._slotName] || 1093984;

  unit.bids.push({
    bidder: "rubicon",
    params: {
      accountId: 10540,
      siteId: 202078,
      zoneId: zoneId,
    },
  });
}

const pubmaticAdSlotIds = {
  "desktop_adhesion_non-us": "2472060",
  desktop_adhesion_unknown: "2472061",
  desktop_adhesion_us: "2472062",
  "desktop_injector_non-us": "2472063",
  desktop_injector_unknown: "2472064",
  desktop_injector_us: "2472065",
  "desktop_rrail_non-us": "2472066",
  desktop_rrail_unknown: "2472067",
  desktop_rrail_us: "2472068",
  "mobile_adhesion_non-us": "2472069",
  mobile_adhesion_unknown: "2472070",
  mobile_adhesion_us: "2472071",
  "mobile_injector_non-us": "2472072",
  mobile_injector_unknown: "2472073",
  mobile_injector_us: "2472074",
  "mobile_rrail_non-us": "2472075",
  mobile_rrail_unknown: "2472076",
  mobile_rrail_us: "2472077",
  "tablet_adhesion_non-us": "2472078",
  tablet_adhesion_unknown: "2472079",
  tablet_adhesion_us: "2472080",
  "tablet_injector_non-us": "2472081",
  tablet_injector_unknown: "2472082",
  tablet_injector_us: "2472083",
  "tablet_rrail_non-us": "2472084",
  tablet_rrail_unknown: "2472085",
  tablet_rrail_us: "2472086",
};

export function addPubmaticBid(unit) {
  if (mutedAnyBidders && !debug["only-pubmatic"]) {
    log("Muting Pubmatic");
    return;
  }
  const adSlot = pubmaticAdSlotIds[unit._slotName];

  unit.bids.push({
    bidder: "pubmatic",
    params: {
      publisherId: "158802",
      adSlot,
    },
  });

  if (getHasSize(unit, "1x3")) {
    unit.bids.push({
      bidder: "pubmatic",
      params: {
        publisherId: "158802",
        adSlot: "6313108",
        outstreamAU: "theatlantic_outstream",
      },
    });
  }
}

const teadsChannelParams = {
  politics: {
    placementId: 148452,
    pageId: 135269,
  },
  ideas: {
    placementId: 148453,
    pageId: 135270,
  },
  technology: {
    placementId: 148454,
    pageId: 135271,
  },
  health: {
    placementId: 148455,
    pageId: 135272,
  },
  culture: {
    placementId: 148456,
    pageId: 135273,
  },
  science: {
    placementId: 148457,
    pageId: 135274,
  },
  books: {
    placementId: 148458,
    pageId: 135275,
  },
  education: {
    placementId: 148459,
    pageId: 135276,
  },
  family: {
    placementId: 148460,
    pageId: 135277,
  },
};

/**
 * Returns channel-specific Teads params if on a channel page
 * (eg channel landing page or article belonging to channel)
 * Otherwise, return default Teads params
 *
 * @param      {String}  zone    The ad unit's zone
 */
function getTeadsParams(zone) {
  // Example zone format: "/4624/theatlantic.web/ideas/article_standard"
  const channel = zone.split("/")[3];
  if (teadsChannelParams[channel]) {
    return teadsChannelParams[channel];
  }
  // On non-channel pages (eg homepage), return default params
  return {
    placementId: 125397,
    pageId: 115526,
  };
}

/**
 * Add the Teads bid information
 *
 * @param      {PrebidUnit}  unit    The prebid unit
 */
export function addTeadsBid(unit) {
  if (mutedAnyBidders && !debug["only-teads"]) {
    log("Muting Teads");
    return;
  }
  const zone = getOptions().zone || "";
  const params = getTeadsParams(zone);

  unit.bids.push({
    bidder: "teads",
    params: params,
  });
}

/**
 * Add the Concert bid information
 */
export function addConcertBid(unit) {
  if (mutedAnyBidders && !debug["only-concert"]) {
    log("Muting Concert");
    return;
  }
  // Only add to 1x3
  let has1x3 = getHasSize(unit, "1x3");

  if (!has1x3) {
    return;
  }

  const placementId = 12345;

  unit.bids.push({
    bidder: "concert",
    params: {
      partnerId: "theatlantic",
      site: "theatlantic",
      placementId: placementId,
      slot: unit._slotName,
    },
  });
}
